import React, { ExoticComponent, Suspense } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import ViewOrgAdminOnboarding from "../views/app/onboarding"

const ViewPrivate: ExoticComponent<any> = React.lazy(() => import("./PrivateRouter"))
const ViewPublic: ExoticComponent<any> = React.lazy(() => import("./PublicRouter"))
const ViewAdminSurveyOld: ExoticComponent<any> = React.lazy(() => import("../views/public/survey"))
const ViewError: ExoticComponent<any> = React.lazy(() => import("../views/errorView"))
const ViewNotFoundError: ExoticComponent<any> = React.lazy(() => import("../views/notFoundView"))
const ViewForbidden: ExoticComponent<any> = React.lazy(() => import("../views/forbiddenView"))

const Routes: React.FC = () => (
  <Suspense fallback={<div className='loading' />}>
    <BrowserRouter>
      <Switch>
        <Route path={`/app/organizations/:organizationId/onboarding`} render={(props) => <ViewOrgAdminOnboarding {...props} />} />
        <Route path='/app' render={(routeProps) => <ViewPrivate {...routeProps} />} />
        <Route path='/public' render={(routeProps) => <ViewPublic {...routeProps} />} />
        <Route path='/admin/survey/:token' render={(routeProps) => <ViewAdminSurveyOld {...routeProps} />} />
        <Route path='/forbidden' render={(routeProps) => <ViewForbidden {...routeProps} />} />
        <Route path='/unknwon-error' render={(routeProps) => <ViewError {...routeProps} />} />
        <Route path='/error' render={(routeProps) => <ViewNotFoundError {...routeProps} />} />
        <Redirect to='/public' />
      </Switch>
    </BrowserRouter>
  </Suspense>
)

export default Routes
