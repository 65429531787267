// tslint:disable: no-any
import ActionType from "../../types/ActionType"
import { ProgramRecipientDto } from "../../types/ProgramsType"
import OutreachQueueActions from "../actions/outreachQueues"
import { OutreachQueueDTO } from "../../views/app/outreachQueues/types"

export interface Failed {
  row: any
  index?: number
  message: string
}

export interface OutreachQueuesStore {
  errorOutreachQueue: string | null
  loadingAddPersonToOutreachQueue: boolean
  loadingRemovePersonToOutreachQueue: boolean
  loadingUpdatePersonToOutreachQueue: boolean
  loadingCreateOutreachQueue: boolean
  loadingEditOutreachQueue: boolean
  loadingOutreachQueues: boolean
  loadingOutreachQueue: boolean
  loadingDeleteOutreachQueue: boolean
  outreachQueues: OutreachQueueDTO[]
  outreachQueue: OutreachQueueDTO | null
  totalPages: number
  changedPersonOrganizationInQueueSuccess: boolean
  changeOutreachQueueSuccess: boolean
  createOutreachQueueSuccess: boolean
  loadingProgramsRecipients: boolean
  programsRecipients: ProgramRecipientDto[]
}

const INITIAL_STATE: OutreachQueuesStore = {
  changeOutreachQueueSuccess: false,
  createOutreachQueueSuccess: false,
  errorOutreachQueue: null,
  outreachQueue: null,
  outreachQueues: [],
  changedPersonOrganizationInQueueSuccess: false,
  loadingAddPersonToOutreachQueue: false,
  loadingRemovePersonToOutreachQueue: false,
  loadingUpdatePersonToOutreachQueue: false,
  loadingCreateOutreachQueue: false,
  loadingEditOutreachQueue: false,
  loadingDeleteOutreachQueue: false,
  loadingOutreachQueues: false,
  loadingOutreachQueue: false,
  totalPages: 1,
  loadingProgramsRecipients: false,
  programsRecipients: [],
}

export default (state = INITIAL_STATE, action: ActionType): OutreachQueuesStore => {
  switch (action.type) {
    case OutreachQueueActions.ACTION_TYPES.CREATE_OUTREACH_QUEUE_TRIGGER: {
      return {
        ...state,
        loadingCreateOutreachQueue: true,
        errorOutreachQueue: null,
        createOutreachQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.CREATE_OUTREACH_QUEUE_SUCCESS: {
      return {
        ...state,
        loadingCreateOutreachQueue: false,
        outreachQueue: action.payload,
        errorOutreachQueue: null,
        createOutreachQueueSuccess: true,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.CREATE_OUTREACH_QUEUE_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        loadingCreateOutreachQueue: false,
        createOutreachQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.EDIT_OUTREACH_QUEUE_TRIGGER: {
      return {
        ...state,
        loadingEditOutreachQueue: true,
        changeOutreachQueueSuccess: false,
        errorOutreachQueue: null,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.EDIT_OUTREACH_QUEUE_SUCCESS: {
      return {
        ...state,
        loadingEditOutreachQueue: false,
        changeOutreachQueueSuccess: true,
        outreachQueue: action.payload.data,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.EDIT_OUTREACH_QUEUE_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        loadingEditOutreachQueue: false,
        changeOutreachQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.DELETE_OUTREACH_QUEUE_TRIGGER: {
      return {
        ...state,
        loadingDeleteOutreachQueue: true,
        errorOutreachQueue: null,
        changeOutreachQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.DELETE_OUTREACH_QUEUE_SUCCESS: {
      return {
        ...state,
        loadingDeleteOutreachQueue: false,
        changeOutreachQueueSuccess: true,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.DELETE_OUTREACH_QUEUE_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        loadingDeleteOutreachQueue: false,
        changeOutreachQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_TRIGGER: {
      return {
        ...state,
        loadingOutreachQueue: true,
        outreachQueue: null,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_SUCCESS: {
      return {
        ...state,
        loadingOutreachQueue: false,
        outreachQueue: action.payload,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        loadingOutreachQueue: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_PERSON_TRIGGER: {
      return {
        ...state,
        loadingOutreachQueue: true,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_PERSON_SUCCESS: {
      return {
        ...state,
        loadingOutreachQueue: false,
        outreachQueue: {
          ...(state.outreachQueue ? state.outreachQueue : {}),
          [`${action.payload.type.toLowerCase()}s`]: action.payload.data,
        } as OutreachQueueDTO,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FETCH_OUTREACH_QUEUE_PERSON_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        loadingOutreachQueue: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FILTER_OUTREACH_QUEUES_TRIGGER: {
      return {
        ...state,
        loadingOutreachQueues: true,
        errorOutreachQueue: null,
        outreachQueue: null,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FILTER_OUTREACH_QUEUES_SUCCESS: {
      return {
        ...state,
        loadingOutreachQueues: false,
        outreachQueues: action.payload.rows,
        totalPages: action.payload.pager.totalPages,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FILTER_OUTREACH_QUEUES_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        loadingOutreachQueues: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.ADD_PERSON_TO_OUTREACH_QUEUES_TRIGGER: {
      return {
        ...state,
        errorOutreachQueue: null,
        loadingAddPersonToOutreachQueue: true,
        changedPersonOrganizationInQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.ADD_PERSON_TO_OUTREACH_QUEUES_SUCCESS: {
      return {
        ...state,
        loadingAddPersonToOutreachQueue: false,
        changedPersonOrganizationInQueueSuccess: true,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.ADD_PERSON_TO_OUTREACH_QUEUES_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        changedPersonOrganizationInQueueSuccess: false,
        loadingAddPersonToOutreachQueue: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.REMOVE_PERSON_TO_OUTREACH_QUEUES_TRIGGER: {
      return {
        ...state,
        errorOutreachQueue: null,
        loadingRemovePersonToOutreachQueue: true,
        changedPersonOrganizationInQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.REMOVE_PERSON_TO_OUTREACH_QUEUES_SUCCESS: {
      return {
        ...state,
        loadingRemovePersonToOutreachQueue: false,
        changedPersonOrganizationInQueueSuccess: true,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.REMOVE_PERSON_TO_OUTREACH_QUEUES_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        changedPersonOrganizationInQueueSuccess: false,
        loadingRemovePersonToOutreachQueue: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.UPDATE_RECIPIENT_OUTREACH_QUEUE_TRIGGER: {
      return {
        ...state,
        errorOutreachQueue: null,
        loadingRemovePersonToOutreachQueue: true,
        changedPersonOrganizationInQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.UPDATE_RECIPIENT_OUTREACH_QUEUE_SUCCESS: {
      return {
        ...state,
        loadingRemovePersonToOutreachQueue: false,
        changedPersonOrganizationInQueueSuccess: true,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.UPDATE_RECIPIENT_OUTREACH_QUEUE_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        changedPersonOrganizationInQueueSuccess: false,
        loadingRemovePersonToOutreachQueue: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_TRIGGER: {
      return {
        ...state,
        errorOutreachQueue: null,
        loadingUpdatePersonToOutreachQueue: true,
        changedPersonOrganizationInQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_SUCCESS: {
      return {
        ...state,
        loadingUpdatePersonToOutreachQueue: false,
        changedPersonOrganizationInQueueSuccess: true,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.UPDATE_PRIORITY_ORDER_OUTREACH_QUEUE_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        changedPersonOrganizationInQueueSuccess: false,
        loadingUpdatePersonToOutreachQueue: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_TRIGGER: {
      return {
        ...state,
        errorOutreachQueue: null,
        loadingRemovePersonToOutreachQueue: true,
        changedPersonOrganizationInQueueSuccess: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_SUCCESS: {
      return {
        ...state,
        loadingRemovePersonToOutreachQueue: false,
        changedPersonOrganizationInQueueSuccess: true,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.PAUSE_RESUME_RECIPIENT_OUTREACH_QUEUE_ERROR: {
      return {
        ...state,
        errorOutreachQueue: action.payload,
        changedPersonOrganizationInQueueSuccess: false,
        loadingRemovePersonToOutreachQueue: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.CLEAR_OUTREACH_QUEUE_ERROR_TRIGGER: {
      return {
        ...state,
        errorOutreachQueue: null,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.CLEAR_OUTREACH_QUEUE_TRIGGER: {
      return {
        ...state,
        changeOutreachQueueSuccess: false,
        createOutreachQueueSuccess: false,
        errorOutreachQueue: null,
        outreachQueue: null,
        changedPersonOrganizationInQueueSuccess: false,
        loadingAddPersonToOutreachQueue: false,
        loadingRemovePersonToOutreachQueue: false,
        loadingCreateOutreachQueue: false,
        loadingEditOutreachQueue: false,
        loadingDeleteOutreachQueue: false,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FETCH_PROGRAMS_RECIPIENTS_TRIGGER: {
      return {
        ...state,
        loadingProgramsRecipients: true,
        programsRecipients: [],
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FETCH_PROGRAMS_RECIPIENTS_SUCCESS: {
      return {
        ...state,
        loadingProgramsRecipients: false,
        programsRecipients: action.payload ?? [],
      }
    }

    case OutreachQueueActions.ACTION_TYPES.FETCH_PROGRAMS_RECIPIENTS_ERROR: {
      return {
        ...state,
        loadingProgramsRecipients: false,
        errorOutreachQueue: action.payload,
      }
    }

    case OutreachQueueActions.ACTION_TYPES.CLEAR_OUTREACH_QUEUES_TRIGGER: {
      return {
        ...state,
        ...INITIAL_STATE,
      }
    }

    default:
      return state
  }
}
