// tslint:disable no-any strict-type-predicates
import { isEmpty } from "lodash"
import { UserProfileNameEnum, validLoginProfiles, UserProfileTypeEnum } from "../constants/views"
import {
  CanRuleEnum,
  Configuration,
  ConfigurationIdentifierEnum,
  LoginData,
  permissionAclByModule,
  PermissionEnum,
  PermissionProfileFullAccess,
  PermissionRuleByModule,
  PersonOrganizationProfile,
} from "../types/AuthType"
import { Feature, Organization } from "../types/OrganizationType"
import { decryptLocalStorage, encryptLocalStorage } from "./CipherHelper"
import { FeaturesEnum } from "../constants/featuresEnum"

export type AxiosRequestHeaders = Record<string, string | number | boolean>

export interface UserAuth {
  organizationId: string | null
  features: Feature[] | null
  activeProfile: string | null
  availableProfiles: string | string[] | null
  authenticationProfiles: string[]
  userId: string | null
  signupCompleted: string | null
  name: string | null
  userName: string | null
  isSuperAdmin: boolean
  hasLoggableProfiles: boolean
  tosStatus: string | null
}

const AllKeys = [
  "callhub_adm_tk",
  "callhub_org_id",
  "callhub_usr_available_profiles",
  "callhub_usr_active_profile",
  "callhub_usr_authentication_profiles",
  "callhub_usr_nm",
  "callhub_userName",
  "callhub_usr_signup",
  "callhub_usr_tos_status",
  "callhub_usr_id",
  "callhub_org_features",
  "callhub_usr_sa",
  "callhub_roles",
  "callhub_selected_org",
]

class AuthHelper {
  public static requiredLocalStorage: string[] = [
    "callhub_adm_tk",
    "callhub_usr_available_profiles",
    "callhub_usr_active_profile",
    "callhub_usr_authentication_profiles",
    "callhub_usr_nm",
    "callhub_usr_id",
    "callhub_org_features",
    "callhub_usr_sa",
    "callhub_userName",
  ]

  public static isLoggedIn(): boolean {
    const item: string | null = localStorage.getItem("callhub_adm_tk")
    return item != null && !isEmpty(item)
  }

  public static getToken(): string | null {
    return localStorage.getItem("callhub_adm_tk")
  }

  public static getAdminHeaders(): AxiosRequestHeaders {
    const token: string | null = this.getToken()
    return { Authorization: `Bearer ${token}` }
  }

  public static hasFeatureAccess(feature: string): boolean {
    try {
      const user: UserAuth = this.getUserAuth()
      if (!user) {
        return false
      }
      const { isSuperAdmin, features } = user
      if (isSuperAdmin) {
        return true
      }
      if (!features || isEmpty(features)) {
        return false
      }
      return (
        features.find((f) => {
          const { name } = f ?? {}
          if (!name) {
            return false
          }
          return name.toLowerCase() === feature.toLowerCase()
        }) != undefined
      )
    } catch (err) {
      console.error("Error validating features: ", err)
      return false
    }
  }

  public static getUserAuth(): UserAuth {
    const featuresStr = decryptLocalStorage("callhub_org_features")
    const sa = decryptLocalStorage("callhub_usr_sa")
    const activeProfile = decryptLocalStorage("callhub_usr_active_profile")
    const authenticationProfiles = decryptLocalStorage("callhub_usr_authentication_profiles") ?? []
    const isSuperAdmin = activeProfile === UserProfileNameEnum.SUPER_ADMIN || (sa ? JSON.parse(sa) : false)
    return {
      organizationId: decryptLocalStorage("callhub_org_id"),
      features: featuresStr ? (featuresStr as any as Feature[]) : null,
      activeProfile,
      availableProfiles: decryptLocalStorage("callhub_usr_available_profiles"),
      authenticationProfiles,
      userId: decryptLocalStorage("callhub_usr_id"),
      signupCompleted: decryptLocalStorage("callhub_usr_signup"),
      name: decryptLocalStorage("callhub_usr_nm"),
      isSuperAdmin,
      hasLoggableProfiles: authenticationProfiles.length > 1 || isSuperAdmin,
      tosStatus: decryptLocalStorage("callhub_usr_tos_status"),
      userName: decryptLocalStorage("callhub_userName"),
    }
  }

  public static needsToCompleteSignUp(): boolean {
    const { signupCompleted, isSuperAdmin }: UserAuth = AuthHelper.getUserAuth()
    if (isSuperAdmin) return false
    return !signupCompleted
  }

  public static clearStorage(): void {
    AllKeys.forEach((key: string) => localStorage.removeItem(key))
  }

  private static getHighestProfilePermission(profiles: PersonOrganizationProfile[]): PersonOrganizationProfile {
    for (const profile of validLoginProfiles) {
      const highestProfile = profiles.find((x) => x.securityProfile.name.toLowerCase() === profile.toLowerCase())
      if (highestProfile) {
        return highestProfile
      }
    }
    throw new Error("User does not have a valid profile")
  }

  public static setUpPersistentData(data: LoginData): void {
    const { token, user, profiles, userName, features, isSuperAdmin }: any = data
    const validPersonOrganizationProfile: PersonOrganizationProfile[] = (profiles as PersonOrganizationProfile[]).filter((p1: PersonOrganizationProfile) => {
      const profileName = p1.securityProfile.name as UserProfileNameEnum
      return validLoginProfiles.includes(profileName)
    })
    if (isEmpty(validPersonOrganizationProfile)) {
      throw new Error("User does not have a valid profile")
    }
    const highestProfilePermission = this.getHighestProfilePermission(validPersonOrganizationProfile)
    const orgFeatures: string[] = features ? features : []
    const activeProfile: string = highestProfilePermission.securityProfile.name
    const profileToken: string = token
    const tosAcceptedStatus: boolean = highestProfilePermission.tosAccepted || false
    const signupCompleted: boolean = highestProfilePermission.organization?.signupCompleted || false
    const loggableProfiles = this.getLoggableProfiles(profiles)

    localStorage.setItem("callhub_adm_tk", profileToken)
    encryptLocalStorage("callhub_org_id", highestProfilePermission.organizationId)
    encryptLocalStorage("callhub_usr_available_profiles", JSON.stringify(profiles))
    encryptLocalStorage("callhub_usr_active_profile", activeProfile)
    encryptLocalStorage("callhub_usr_authentication_profiles", loggableProfiles)
    encryptLocalStorage("callhub_usr_nm", `${user.firstName} ${user.lastName}`)
    encryptLocalStorage("callhub_userName", userName)
    encryptLocalStorage("callhub_usr_signup", signupCompleted)
    encryptLocalStorage("callhub_usr_tos_status", tosAcceptedStatus)
    encryptLocalStorage("callhub_usr_id", user.id)
    encryptLocalStorage("callhub_org_features", JSON.stringify(orgFeatures))
    encryptLocalStorage("callhub_usr_sa", `${!!isSuperAdmin}`)
  }

  public static isPersistentDataComplete(): boolean {
    let complete = true
    const { isSuperAdmin }: UserAuth = AuthHelper.getUserAuth()
    const requiredItems: string[] = AuthHelper.requiredLocalStorage.concat(isSuperAdmin ? [] : ["callhub_org_id"])

    requiredItems.forEach((item) => {
      let savedItem: string | null = null

      if (item === "callhub_adm_tk") {
        savedItem = localStorage.getItem(item)
      } else {
        savedItem = decryptLocalStorage(item)
      }

      if (savedItem === null || savedItem === undefined) {
        complete = false
      }
    })

    return complete
  }

  public static setTosAccepted = (tosAccepted: string) => encryptLocalStorage("callhub_usr_tos_status", tosAccepted)
  public static setSignup = (signup: string) => encryptLocalStorage("callhub_usr_signup", signup)
  public static setRoles = (roles: string) => encryptLocalStorage("callhub_roles", roles)

  public static getAvailableFeatureRouteList = (): string[] => (window._env_.AVAILABLE_FEATURES ?? "").split(",")

  public static isEnvironmentFeatureAvailable(feature: string): boolean {
    const featureIsAvailable = AuthHelper.getAvailableFeatureRouteList().find((x) => x.toLowerCase() === feature.toLowerCase())
    return !!featureIsAvailable
  }
  public static userHasFeatureEnabled = (feature: FeaturesEnum): boolean => {
    const { isSuperAdmin, features }: UserAuth = AuthHelper.getUserAuth()
    const hasFeatureEnabled = isSuperAdmin || (features ?? []).some((x) => x.name === feature)
    return hasFeatureEnabled
  }
  public static getUserPermissionForModule = (module: PermissionEnum): PermissionRuleByModule => {
    const { activeProfile, isSuperAdmin } = AuthHelper.getUserAuth()
    if (isSuperAdmin) {
      return {
        module,
        profile: UserProfileNameEnum.SUPER_ADMIN,
        canEdit: PermissionProfileFullAccess.canRules.some((r) => r === CanRuleEnum.EDIT) ?? false,
        canCreate: PermissionProfileFullAccess.canRules.some((r) => r === CanRuleEnum.CREATE) ?? false,
        canDeleteAsAdmin: PermissionProfileFullAccess.canRules.some((r) => r === CanRuleEnum.DELETE) ?? false,
        canReadAsAdmin: PermissionProfileFullAccess.canRules.some((r) => r === CanRuleEnum.READ) ?? false,
      }
    }
    if (!activeProfile) {
      return {
        module,
        profile: UserProfileNameEnum.NOT_PROFILE,
        canEdit: false,
        canCreate: false,
        canDeleteAsAdmin: false,
        canReadAsAdmin: false,
      }
    }
    const permissionProfileByModule = permissionAclByModule[module]?.find((x) => x.profile.toLowerCase() === activeProfile.toLowerCase()) ?? null
    return {
      module,
      profile: activeProfile as UserProfileNameEnum,
      canEdit: permissionProfileByModule?.canRules.some((r) => r === CanRuleEnum.EDIT) ?? false,
      canCreate: permissionProfileByModule?.canRules.some((r) => r === CanRuleEnum.CREATE) ?? false,
      canDeleteAsAdmin: permissionProfileByModule?.canRules.some((r) => r === CanRuleEnum.DELETE) ?? false,
      canReadAsAdmin: permissionProfileByModule?.canRules.some((r) => r === CanRuleEnum.READ) ?? false,
    }
  }

  static setSelectedOrganization(orgSelected: Organization) {
    encryptLocalStorage("callhub_org_id", orgSelected.id)
    encryptLocalStorage("callhub_selected_org", JSON.stringify(orgSelected))
  }

  static removeSelectedOrganization() {
    localStorage.removeItem("callhub_org_id")
    localStorage.removeItem("callhub_selected_org")
  }

  static getSelectedOrganization(): Organization | null {
    const orgSelected = decryptLocalStorage("callhub_selected_org")
    if (!orgSelected) {
      return null
    }
    return orgSelected
  }

  static getConfigurations(): Configuration[] {
    const { configurations } = this.getSelectedOrganization() ?? {}
    return configurations ?? []
  }

  static isConfigurationEnabled = (config: ConfigurationIdentifierEnum): boolean => {
    const configurations = AuthHelper.getConfigurations() ?? []
    return configurations.some((x) => x.identifier === config && x.active)
  }

  static getLoggableProfiles = (activeProfiles): Pick<UserAuth, "activeProfile">[] => {
    return activeProfiles.filter((profile) => profile.active === true && profile.securityProfile.type.toLowerCase() === UserProfileTypeEnum.LOGGABLE.toLowerCase())
  }

  static logout = () => {
    AuthHelper.clearStorage()
    window.location.href = "public/login"
  }
}

export default AuthHelper
