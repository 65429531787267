// tslint:disable:
import ActionType from "../../types/ActionType"
import Auth from "../actions/auth"
import { PasswordStatuses } from "../../constants/roles"
import { Feature } from "../../types/OrganizationType"

export enum CreatePasswordStatus {
  INCOMPLETE = 0,
  UPDATING,
  SUCCESS,
  ERROR,
}

export enum ForgotPasswordStatus {
  PRISTINE = 0,
  UPDATING,
  SUCCESS,
  ERROR,
}

export enum UpdatePasswordStatus {
  PRISTINE = 0,
  UPDATING,
  SUCCESS,
  ERROR,
}

export enum ResetPasswordStatus {
  PRISTINE = 0,
  UPDATING,
  SUCCESS,
  ERROR,
}

export interface AuthStore {
  createPasswordStatus: CreatePasswordStatus
  forgotPasswordErrorMessage: string
  forgotPasswordStatus: ForgotPasswordStatus
  loginErrorMessage: string
  loginLoading: boolean
  passwordExpired: boolean
  registerErrorMessage: string
  registerLoading: boolean
  resetPasswordErrorMessage: string
  resetPasswordStatus: ResetPasswordStatus
  updatePasswordErrorMessage: string
  updatePasswordStatus: UpdatePasswordStatus
  userName: string
  features?: Feature[] | null
  isSuperAdmin?: boolean
  userStatusLoading: boolean
  userStatusError?: string
}

export const INITIAL_STATE: AuthStore = {
  createPasswordStatus: CreatePasswordStatus.INCOMPLETE,
  forgotPasswordErrorMessage: "",
  forgotPasswordStatus: ForgotPasswordStatus.PRISTINE,
  loginErrorMessage: "",
  loginLoading: false,
  passwordExpired: false,
  registerErrorMessage: "",
  registerLoading: false,
  resetPasswordErrorMessage: "",
  resetPasswordStatus: ResetPasswordStatus.PRISTINE,
  updatePasswordErrorMessage: "",
  updatePasswordStatus: UpdatePasswordStatus.PRISTINE,
  userName: "",
  userStatusLoading: false,
}

export default (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case Auth.LOGIN_ADMIN_TRIGGERED:
      return {
        ...state,
        loginErrorMessage: "",
        loginLoading: true,
      }

    case Auth.LOGIN_ADMIN_SUCCESS:
      return {
        ...state,
        loginErrorMessage: "",
        loginLoading: false,
        passwordExpired: action.payload.user.passwordStatus === PasswordStatuses.EXPIRED,
        features: action.payload.user?.features,
        isSuperAdmin: action.payload.user?.isSuperAdmin,
      }

    case Auth.LOGIN_ADMIN_ERROR:
      return {
        ...state,
        loginErrorMessage: action.payload,
        loginLoading: false,
      }

    case Auth.LOGOUT_ADMIN:
      return {
        ...state,
        passwordExpired: null,
        features: undefined,
        isSuperAdmin: undefined,
      }

    case Auth.CREATE_ADMIN_PASSWORD_TRIGGER: {
      return {
        ...state,
        createPasswordStatus: CreatePasswordStatus.UPDATING,
      }
    }
    case Auth.CREATE_ADMIN_PASSWORD_SUCCESS: {
      return {
        ...state,
        createPasswordStatus: CreatePasswordStatus.SUCCESS,
      }
    }
    case Auth.CREATE_ADMIN_PASSWORD_ERROR: {
      return {
        ...state,
        createPasswordStatus: CreatePasswordStatus.ERROR,
      }
    }
    case Auth.RESET_ADMIN_PASSWORD_TRIGGER: {
      return {
        ...state,
        resetPasswordStatus: ResetPasswordStatus.UPDATING,
      }
    }
    case Auth.RESET_ADMIN_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetPasswordStatus: ResetPasswordStatus.SUCCESS,
      }
    }
    case Auth.RESET_ADMIN_PASSWORD_ERROR: {
      return {
        ...state,
        resetPasswordErrorMessage: action.payload,
        resetPasswordStatus: ResetPasswordStatus.ERROR,
      }
    }

    case Auth.FORGOT_ADMIN_PASSWORD_TRIGGER: {
      return {
        ...state,
        forgotPasswordStatus: ForgotPasswordStatus.UPDATING,
      }
    }

    case Auth.FORGOT_ADMIN_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgotPasswordStatus: ForgotPasswordStatus.SUCCESS,
      }
    }

    case Auth.FORGOT_ADMIN_PASSWORD_ERROR: {
      return {
        ...state,
        forgotPasswordErrorMessage: action.payload,
        forgotPasswordStatus: ForgotPasswordStatus.ERROR,
      }
    }

    case Auth.GET_USER_STATUS_TRIGGER: {
      return {
        ...state,
        userStatusLoading: true,
      }
    }

    case Auth.GET_USER_STATUS_SUCCESS: {
      return {
        ...state,
        userName: action.payload.userName,
        passwordExpired: action.payload.passwordStatus === PasswordStatuses.EXPIRED,
        features: action.payload.user?.features,
        isSuperAdmin: action.payload.user?.isSuperAdmin,
        userStatusLoading: false,
      }
    }

    case Auth.GET_USER_STATUS_ERROR: {
      return {
        ...state,
        userName: null,
        passwordExpired: null,
        features: null,
        isSuperAdmin: false,
        userStatusLoading: false,
        userStatusError: action.payload,
      }
    }

    case Auth.PASSWORD_UPDATED: {
      return {
        ...state,
        passwordExpired: false,
        updatePasswordStatus: UpdatePasswordStatus.SUCCESS,
        updatePasswordErrorMessage: "",
      }
    }

    case Auth.PASSWORD_UPDATE_ERROR: {
      return {
        ...state,
        updatePasswordErrorMessage: action.payload.errorId,
      }
    }

    case Auth.RESET_UPDATE_PASSWORD_ERROR: {
      return {
        ...state,
        updatePasswordErrorMessage: "",
        updatePasswordStatus: UpdatePasswordStatus.PRISTINE,
      }
    }

    default:
      return { ...state }
  }
}
