import { AxiosResponse } from "axios"
import { Dispatch } from "redux"

import API from "../../Api"
import { RoleType } from "../../constants/roles"
import { UserProfileNameEnum } from "../../constants/views"
import AuthHelper from "../../helpers/AuthHelper"
import { getPersonOrganizationForBody, getPersonOrganizationsForEdit } from "../../helpers/FormatHelper"
import { openInNewTab } from "../../helpers/NavigationHelper"
import { getPersonOrganizationsForExport } from "../../helpers/ParserHelper"
import { CreatePersonOrganization, EditPersonOrganizations, NamedProfile } from "../../types/PersonType"
import { CompleteSurveyType } from "../../types/SurveyType"
import PhoneUtils from "../../utils/PhoneUtils"
import { PersonOrganizationMatchDataType } from "../../views/app/users/personOrganizations/PersonOrganizationMatchForm"
import { PersonOrganizationSendSurveyDataType } from "../../views/app/users/personOrganizations/PersonOrganizationSendSurveyForm"
import Modals from "./modals"
import { CreateInitialValuesManager } from "./organizations"

export interface PersonOrganizationsParams {
  search?: string
  page?: number
  role?: RoleType | RoleType[]
  organizationId?: string
  personId?: string
  active?: boolean
}

export interface PersonOrganizationsRolePrograms {
  organizationId?: string
  personId?: string
}

export interface RemoveMatchPersonOrganizationParams {
  callerId: string
  calleeId: string
  roleId: string
}

interface CreateNewOrganizationAdminBody {
  firstName: string
  lastName: string
  phone?: string | null
  email: string
  userName: string
  password: string
  organizationProfiles: [
    {
      securityProfileId: string
      organizationId: string
    },
  ]
}

type ClearExportedPersonOrganizations = () => (dispatch: Dispatch) => void
type CreateMatchPersonOrganization = (values: PersonOrganizationMatchDataType) => (dispatch: Dispatch) => void
type CreatePersonOrganizationSurvey = (values: PersonOrganizationSendSurveyDataType) => (dispatch: Dispatch) => void
type OpenPersonOrganizationSurvey = (survey: CompleteSurveyType) => (dispatch: Dispatch) => void
type ClearPersonOrganizations = () => void
type ClearState = () => any
type CreateOrganizationAdmin = (values: CreateInitialValuesManager, organizationId: string) => (dipatch: Dispatch) => void
type CreatePersonOrganizationAction = (params: CreatePersonOrganization) => (dispatch: Dispatch) => void
type UpdateRolePersonOrganization = (personOrganizationRoleProgramId: string, roleName: string) => (dispatch: Dispatch) => void
type UpdateRecipientToOnboardingPersonOrganization = (personOrganizationRoleProgramId: string) => (dispatch: Dispatch) => void
type DeletePersonOrganization = (id: string) => (dispatch: Dispatch) => void
type EditPersonOrganizationsType = (id: string, values: EditPersonOrganizations) => (dispatch: Dispatch) => void
type ExportPersonOrganizations = (orgData: OrgDataToExport) => (dispatch: Dispatch) => void
type FetchPersonOrganization = (id: string) => (dispatch: Dispatch) => void
type FetchPersonOrganizations = (params: PersonOrganizationsParams) => (dispatch: Dispatch) => void
type FetchPersonOrganizationsByOrganizationId = (params: PersonOrganizationsParams) => (dispatch: Dispatch) => void
type RemoveMatchPersonOrganization = (params: RemoveMatchPersonOrganizationParams) => (dispatch: Dispatch) => void

interface OrgDataToExport {
  organizationId: string
  role?: RoleType
  active?: boolean
  extraFields?: string[]
}

export interface PersonOrganizationActions {
  clearEditStatus: ClearState
  clearExportedPersonOrganizations: ClearExportedPersonOrganizations
  clearPersonOrganizations: ClearPersonOrganizations
  clearUsernameAlreadyExistsMsg: ClearState
  createOrganizationAdmin: CreateOrganizationAdmin
  createPersonOrganization: CreatePersonOrganizationAction
  createMatchPersonOrganization: CreateMatchPersonOrganization
  createPersonOrganizationSurvey: CreatePersonOrganizationSurvey
  openPersonOrganizationSurvey: OpenPersonOrganizationSurvey
  updateRolePersonOrganization: UpdateRolePersonOrganization
  updateRecipientToOnboardingPersonOrganization: UpdateRecipientToOnboardingPersonOrganization
  deletePersonOrganization: DeletePersonOrganization
  editPersonOrganizations: EditPersonOrganizationsType
  exportPersonOrganizations: ExportPersonOrganizations
  fetchPersonOrganizations: FetchPersonOrganizations
  fetchPersonOrganization: FetchPersonOrganization
  fetchPersonOrganizationsByOrganizationId: FetchPersonOrganizationsByOrganizationId
  removeMatchPersonOrganization: RemoveMatchPersonOrganization
}

class PersonOrganizations {
  public static CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_SUCCESS = "CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_SUCCESS"
  public static CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_ERROR = "CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_ERROR"
  public static CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_TRIGGER = "CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_TRIGGER"

  public static CREATE_ORGANIZATION_PERSON_ADMIN_SUCCESS = "CREATE_ORGANIZATION_PERSON_ADMIN_SUCCESS"
  public static CREATE_ORGANIZATION_PERSON_ADMIN_ERROR = "CREATE_ORGANIZATION_PERSON_ADMIN_ERROR"
  public static CREATE_ORGANIZATION_PERSON_ADMIN_TRIGGER = "CREATE_ORGANIZATION_PERSON_ADMIN_TRIGGER"

  public static EDIT_PERSON_ORGANIZATIONS_SUCCESS = "EDIT_PERSON_ORGANIZATIONS_SUCCESS"
  public static EDIT_PERSON_ORGANIZATIONS_ERROR = "EDIT_PERSON_ORGANIZATIONS_ERROR"
  public static EDIT_PERSON_ORGANIZATIONS_TRIGGER = "EDIT_PERSON_ORGANIZATIONS_TRIGGER"

  public static CLEAR_PERSON_ORGANIZATIONS = "CLEAR_PERSON_ORGANIZATIONS"
  public static CLEAR_PERSON_ORGANIZATIONS_EDIT_STATUS = "CLEAR_PERSON_ORGANIZATIONS_EDIT_STATUS"

  public static FETCH_PERSON_ORGANIZATIONS_SUCCESS = "FETCH_PERSON_ORGANIZATIONS_SUCCESS"
  public static FETCH_PERSON_ORGANIZATIONS_ERROR = "FETCH_PERSON_ORGANIZATIONS_ERROR"
  public static FETCH_PERSON_ORGANIZATIONS_TRIGGER = "FETCH_PERSON_ORGANIZATIONS_TRIGGER"

  public static FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_SUCCESS = "FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_SUCCESS"
  public static FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_ERROR = "FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_ERROR"
  public static FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_TRIGGER = "FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_TRIGGER"

  public static FETCH_PERSON_ORGANIZATION_SUCCESS = "FETCH_PERSON_ORGANIZATION_SUCCESS"
  public static FETCH_PERSON_ORGANIZATION_ERROR = "FETCH_PERSON_ORGANIZATION_ERROR"
  public static FETCH_PERSON_ORGANIZATION_TRIGGER = "FETCH_PERSON_ORGANIZATION_TRIGGER"

  public static EXPORT_PERSON_ORGANIZATIONS_TRIGGER = "EXPORT_PERSON_ORGANIZATIONS_TRIGGER"
  public static EXPORT_PERSON_ORGANIZATIONS_SUCCESS = "EXPORT_PERSON_ORGANIZATIONS_SUCCESS"
  public static EXPORT_PERSON_ORGANIZATIONS_ERROR = "EXPORT_PERSON_ORGANIZATIONS_ERROR"

  public static COMPLETE_PERSON_ORGANIZATION_SURVEY_TRIGGER = "COMPLETE_PERSON_ORGANIZATION_SURVEY_TRIGGER"
  public static COMPLETE_PERSON_ORGANIZATION_SURVEY_SUCCESS = "COMPLETE_PERSON_ORGANIZATION_SURVEY_SUCCESS"
  public static COMPLETE_PERSON_ORGANIZATION_SURVEY_ERROR = "COMPLETE_PERSON_ORGANIZATION_SURVEY_ERROR"
  public static OPEN_PERSON_ORGANIZATION_SURVEY_TRIGGER = "OPEN_PERSON_ORGANIZATION_SURVEY_TRIGGER"
  public static OPEN_PERSON_ORGANIZATION_SURVEY_SUCCESS = "OPEN_PERSON_ORGANIZATION_SURVEY_SUCCESS"
  public static OPEN_PERSON_ORGANIZATION_SURVEY_ERROR = "OPEN_PERSON_ORGANIZATION_SURVEY_ERROR"

  public static EXPORT_PERSON_ORGANIZATIONS_CLEAR = "EXPORT_PERSON_ORGANIZATIONS_CLEAR"

  public static DELETE_PERSON_ORGANIZATION_TRIGGER = "DELETE_PERSON_ORGANIZATION_TRIGGER"
  public static DELETE_PERSON_ORGANIZATION_SUCCESS = "DELETE_PERSON_ORGANIZATION_SUCCESS"
  public static DELETE_PERSON_ORGANIZATION_ERROR = "DELETE_PERSON_ORGANIZATION_ERROR"

  public static UPDATE_ROLE_PERSON_ORGANIZATION_TRIGGER = "UPDATE_ROLE_PERSON_ORGANIZATION_TRIGGER"
  public static UPDATE_ROLE_PERSON_ORGANIZATION_SUCCESS = "UPDATE_ROLE_PERSON_ORGANIZATION_SUCCESS"
  public static UPDATE_ROLE_PERSON_ORGANIZATION_ERROR = "UPDATE_ROLE_PERSON_ORGANIZATION_ERROR"

  public static UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_TRIGGER = "UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_TRIGGER"
  public static UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_SUCCESS = "UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_SUCCESS"
  public static UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_ERROR = "UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_ERROR"

  public static REMOVE_MATCH_PERSON_ORGANIZATION_SUCCESS = "REMOVE_MATCH_PERSON_ORGANIZATION_SUCCESS"
  public static REMOVE_MATCH_PERSON_ORGANIZATION_ERROR = "REMOVE_MATCH_PERSON_ORGANIZATION_ERROR"
  public static REMOVE_MATCH_PERSON_ORGANIZATION_TRIGGER = "REMOVE_MATCH_PERSON_ORGANIZATION_TRIGGER"

  public static USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS"
  public static USER_ALREADY_EXISTS_CLEAR = "USER_ALREADY_EXISTS_CLEAR"

  public static fetchPersonOrganizations: FetchPersonOrganizations = (payload) => async (dispatch) => {
    try {
      const { role, search, page, organizationId, personId, active } = payload
      dispatch({
        type: PersonOrganizations.FETCH_PERSON_ORGANIZATIONS_TRIGGER,
      })

      const {
        data: { data },
      } = await API.get("/v2/personorganizations", {
        params: {
          role: role || "",
          search: search || "",
          page: page || page === 0 ? page : 1,
          organizationId: organizationId || "",
          personId,
          active,
        },
        headers: AuthHelper.getAdminHeaders(),
      })
      dispatch({
        payload: data,
        type: PersonOrganizations.FETCH_PERSON_ORGANIZATIONS_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: PersonOrganizations.FETCH_PERSON_ORGANIZATIONS_ERROR,
      })
    }
  }
  public static fetchPersonOrganizationsByOrganizationId: FetchPersonOrganizationsByOrganizationId = (params) => async (dispatch) => {
    let res: AxiosResponse
    dispatch({
      type: PersonOrganizations.FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_TRIGGER,
    })

    try {
      res = await API.get("/v2/personorganizations", {
        params,
        headers: AuthHelper.getAdminHeaders(),
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: PersonOrganizations.FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_ERROR,
      })

      return
    }
    dispatch({
      payload: {
        personOrganizations: res.data.data.personOrganizations,
        pager: res.data.data.pager,
        role: params.role || RoleType.RECIPIENT,
      },
      type: PersonOrganizations.FETCH_ORGANIZATION_PERSON_ORGANIZATIONS_SUCCESS,
    })
  }

  public static fetchPersonOrganization: FetchPersonOrganization = (id) => async (dispatch) => {
    try {
      dispatch({
        type: PersonOrganizations.FETCH_PERSON_ORGANIZATION_TRIGGER,
      })
      const res = await API.get(`/v2/personorganizations/${id}`, {
        headers: AuthHelper.getAdminHeaders(),
      })
      dispatch({
        payload: res.data.data,
        type: PersonOrganizations.FETCH_PERSON_ORGANIZATION_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: PersonOrganizations.FETCH_PERSON_ORGANIZATION_ERROR,
      })
      return
    }
  }
  public static editPersonOrganizations: EditPersonOrganizationsType = (id, values) => async (dispatch: Dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: PersonOrganizations.EDIT_PERSON_ORGANIZATIONS_TRIGGER,
    })

    try {
      res = await API.put(`/v2/personorganizations/${id}`, getPersonOrganizationsForEdit(values), { headers: AuthHelper.getAdminHeaders() })
    } catch (err) {
      const usrExistsRegex = new RegExp('^user\\s*name\\s*".*?"\\s*already\\s*exists$', "i")
      if (err.response?.data?.message.match(usrExistsRegex)) {
        dispatch({
          type: PersonOrganizations.USER_ALREADY_EXISTS,
          payload: {
            message: err.response.data.message,
          },
        })
        return
      }

      let errorMessage: string = err.response?.data?.message ?? err.response?.data?.validation?.body?.message ?? "Oops!... Looks like an error occurred!"

      if (errorMessage.indexOf("because") > -1) {
        errorMessage = errorMessage.substring(errorMessage.lastIndexOf("[") + 1, errorMessage.indexOf("]"))
      }

      dispatch({
        payload: errorMessage,
        type: PersonOrganizations.EDIT_PERSON_ORGANIZATIONS_ERROR,
      })

      return
    }
    dispatch({
      payload: res.data.data.personOrganization,
      type: PersonOrganizations.EDIT_PERSON_ORGANIZATIONS_SUCCESS,
    })
  }

  public static createPersonOrganization = (values: CreatePersonOrganization) => async (dispatch: Dispatch) => {
    let res: AxiosResponse
    dispatch({
      type: PersonOrganizations.CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_TRIGGER,
    })
    Modals.createModalTrigger(dispatch)

    try {
      res = await API.post("/v2/personorganizations", getPersonOrganizationForBody(values), { headers: AuthHelper.getAdminHeaders() })

      if (res.data.data) {
        Modals.createModalSuccess(dispatch)
        dispatch({
          type: PersonOrganizations.CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_SUCCESS,
        })
      }
    } catch (err) {
      const errorMessage: string = err.response?.data?.validation?.body?.message ?? err.response?.data?.message ?? "Oops!... Looks like an error occurred!"
      dispatch({
        payload: errorMessage,
        type: PersonOrganizations.CREATE_ORGANIZATION_PERSON_ORGANIZATIONS_ERROR,
      })
      Modals.createModalError(dispatch, errorMessage)
    }
  }

  public static createOrganizationAdmin: CreateOrganizationAdmin = (values, organizationId) => async (dispatch) => {
    let profilesResponse: AxiosResponse

    try {
      profilesResponse = await API.get("/v2/profiles", { headers: AuthHelper.getAdminHeaders() })
      const orgAdmJson: NamedProfile = profilesResponse.data.data.securityProfiles.find((profile: NamedProfile) => profile.name === UserProfileNameEnum.ORG_ADMIN)
      const body: CreateNewOrganizationAdminBody = {
        firstName: values.firstName,
        lastName: values.lastName,
        userName: values.userName,
        email: values.email,
        phone: PhoneUtils.parseToInternational(values.phone),
        password: values.password,
        organizationProfiles: [
          {
            organizationId,
            securityProfileId: orgAdmJson.id,
          },
        ],
      }
      await API.post("/v2/people", body, { headers: AuthHelper.getAdminHeaders() })

      Modals.createModalSuccess(dispatch)
      dispatch({
        type: PersonOrganizations.CREATE_ORGANIZATION_PERSON_ADMIN_SUCCESS,
      })
    } catch (err) {
      const errorMessage: string = err.response?.data?.message || "Oops!... Looks like an error occurred!"
      dispatch({
        payload: errorMessage,
        type: PersonOrganizations.CREATE_ORGANIZATION_PERSON_ADMIN_ERROR,
      })
      Modals.createModalError(dispatch, errorMessage)
    }
  }

  public static exportPersonOrganizations: ExportPersonOrganizations = (payload) => async (dispatch) => {
    try {
      const { organizationId, role, active, extraFields } = payload
      dispatch({
        type: PersonOrganizations.EXPORT_PERSON_ORGANIZATIONS_TRIGGER,
      })
      const res = await API.get("/v2/personorganizations", {
        params: {
          organizationId,
          role,
          matches: true,
          active,
          ...(extraFields ? { extraFields } : {}),
        },
        headers: AuthHelper.getAdminHeaders(),
      })

      dispatch({
        type: PersonOrganizations.EXPORT_PERSON_ORGANIZATIONS_SUCCESS,
        payload: getPersonOrganizationsForExport(res.data.data.personOrganizations, extraFields),
      })

      return
    } catch (err) {
      dispatch({
        type: PersonOrganizations.EXPORT_PERSON_ORGANIZATIONS_ERROR,
      })

      return
    }
  }

  public static createMatchPersonOrganization: CreateMatchPersonOrganization = (values) => async (dispatch) => {
    let response: AxiosResponse
    Modals.createModalTrigger(dispatch)

    try {
      response = await API.post("/v2/personorganizations/match", values, { headers: AuthHelper.getAdminHeaders() })

      if (response.data.data) {
        Modals.createModalSuccess(dispatch)
      }

      return
    } catch (err) {
      if (err) {
        const errorMessage: string = err.response?.data?.message || "Oops!... Looks like an error occurred!"
        Modals.createModalError(dispatch, errorMessage)
      }

      return
    }
  }

  public static completeSurveyPersonOrganization: CreatePersonOrganizationSurvey =
    ({ toPersonOrganizationRoleProgramId, surveyType, respondentPersonOrganizationRoleProgramId }) =>
    async (dispatch) => {
      try {
        Modals.createModalTrigger(dispatch)
        dispatch({
          type: PersonOrganizations.COMPLETE_PERSON_ORGANIZATION_SURVEY_TRIGGER,
        })
        const {
          data: { data },
        } = await API.put(
          `/v2/personorganizations/${toPersonOrganizationRoleProgramId}/surveys`,
          {
            surveyType,
            respondentPersonOrganizationRoleProgramId,
          },
          { headers: AuthHelper.getAdminHeaders() },
        )
        if (data) {
          Modals.createModalSuccess(dispatch)
        }
        if (!data && !data.linkTo) {
          dispatch({
            type: PersonOrganizations.COMPLETE_PERSON_ORGANIZATION_SURVEY_ERROR,
            payload: "Oops!... Looks like an error occurred!",
          })
          return
        }
        dispatch({
          type: PersonOrganizations.COMPLETE_PERSON_ORGANIZATION_SURVEY_SUCCESS,
          payload: data,
        })
      } catch (err) {
        console.error("Error completeSurveyPersonOrganization", err)
        const errorMessage: string = err?.response?.data?.message || "Oops!... Looks like an error occurred!"
        Modals.createModalError(dispatch, errorMessage)
        dispatch({
          type: PersonOrganizations.COMPLETE_PERSON_ORGANIZATION_SURVEY_ERROR,
          payload: errorMessage,
        })
      }
    }
  public static openSurveyPersonOrganization: OpenPersonOrganizationSurvey = (survey) => async (dispatch) => {
    try {
      dispatch({
        type: PersonOrganizations.OPEN_PERSON_ORGANIZATION_SURVEY_TRIGGER,
      })
      dispatch({
        type: PersonOrganizations.OPEN_PERSON_ORGANIZATION_SURVEY_SUCCESS,
        payload: survey,
      })
      const { linkTo } = survey
      openInNewTab(linkTo)
    } catch (err) {
      const errorMessage: string = err?.response?.data?.message || "Oops!... Looks like an error occurred!"
      dispatch({
        type: PersonOrganizations.OPEN_PERSON_ORGANIZATION_SURVEY_ERROR,
        payload: errorMessage,
      })
    }
  }

  public static removeMatchPersonOrganization: RemoveMatchPersonOrganization = (params) => async (dispatch: Dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: PersonOrganizations.REMOVE_MATCH_PERSON_ORGANIZATION_TRIGGER,
    })

    try {
      res = await API.post("/v2/personorganizations/unmatch", params, { headers: AuthHelper.getAdminHeaders() })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: PersonOrganizations.REMOVE_MATCH_PERSON_ORGANIZATION_ERROR,
      })

      return
    }

    dispatch({
      payload: res.data.data,
      type: PersonOrganizations.REMOVE_MATCH_PERSON_ORGANIZATION_SUCCESS,
    })
  }

  public static deletePersonOrganization: DeletePersonOrganization = (id) => async (dispatch) => {
    let res: AxiosResponse

    dispatch({
      type: PersonOrganizations.DELETE_PERSON_ORGANIZATION_TRIGGER,
    })

    try {
      // console.log(`/v2/personorganizations/${id}`)
      res = await API.delete(`/v2/personorganizations/${id}`, { headers: AuthHelper.getAdminHeaders() })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: PersonOrganizations.DELETE_PERSON_ORGANIZATION_ERROR,
      })

      return
    }

    dispatch({
      payload: res.data.data,
      type: PersonOrganizations.DELETE_PERSON_ORGANIZATION_SUCCESS,
    })
  }

  public static updateRolePersonOrganization: UpdateRolePersonOrganization = (personOrganizationRoleProgramId: string, roleName: string) => async (dispatch) => {
    try {
      dispatch({
        type: PersonOrganizations.UPDATE_ROLE_PERSON_ORGANIZATION_TRIGGER,
      })
      const res = await API.put(`/v2/personorganizations/${personOrganizationRoleProgramId}/roles/${roleName}`, {}, { headers: AuthHelper.getAdminHeaders() })
      dispatch({
        payload: res.data.data,
        type: PersonOrganizations.UPDATE_ROLE_PERSON_ORGANIZATION_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.message || "Oops!... Looks like an error occurred!",
        type: PersonOrganizations.UPDATE_ROLE_PERSON_ORGANIZATION_ERROR,
      })
    }
  }

  public static updateRecipientToOnboardingPersonOrganization: UpdateRecipientToOnboardingPersonOrganization = (personOrganizationRoleProgramId: string) => async (dispatch) => {
    try {
      dispatch({
        type: PersonOrganizations.UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_TRIGGER,
      })
      const res = await API.put(`/v2/personorganizations/${personOrganizationRoleProgramId}/onboarding`, {}, { headers: AuthHelper.getAdminHeaders() })
      dispatch({
        payload: res.data.data,
        type: PersonOrganizations.UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_SUCCESS,
      })
    } catch (err) {
      dispatch({
        payload: err.response?.data?.error || "Oops!... Looks like an error occurred!",
        type: PersonOrganizations.UPDATE_RECIPIENT_TO_ONBOARDING_PERSON_ORGANIZATION_ERROR,
      })
    }
  }

  public static clearExportedPersonOrganizations = () => (dispatch: Dispatch) => {
    dispatch({
      type: PersonOrganizations.EXPORT_PERSON_ORGANIZATIONS_CLEAR,
    })
  }

  public static clearPersonOrganizations = () => async (dispatch: Dispatch) => {
    dispatch({
      type: PersonOrganizations.CLEAR_PERSON_ORGANIZATIONS,
    })
  }

  public static clearUsernameAlreadyExistsMsg: ClearState = () => ({
    type: PersonOrganizations.USER_ALREADY_EXISTS_CLEAR,
  })

  public static clearEditStatus: ClearState = () => ({
    type: PersonOrganizations.CLEAR_PERSON_ORGANIZATIONS_EDIT_STATUS,
  })
}

export default PersonOrganizations
