// tslint:disable:no-any
import { AxiosResponse } from "axios"
import { Dispatch } from "redux"

import API from "../../Api"
import AuthHelper from "../../helpers/AuthHelper"
import { getAnalyticsForExportReportOne, getAnalyticsForExportReportThree, getAnalyticsForExportReportTwo } from "../../helpers/ParserHelper"
import { pickBy } from "lodash"
import { ReportRequestModuleEnum } from "../../api/reportrequest/reportrequest.dto"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import { type } from "@testing-library/user-event/dist/type"
import { duration } from "moment-timezone"
import { toast } from "react-toastify"

dayjs.extend(utc)
dayjs.extend(timezone)

interface OrgDataToExport {
  organizationId?: string
  programId?: string
  dateFrom?: string
  dateTo?: string
  extraFields?: string[]
}

export interface CallAnalyticsParams {
  organizationId?: string
  programId?: string
  dateFrom?: string
  dateTo?: string
}

export type ExportCallAnalytics = (params: OrgDataToExport) => (dispatch: Dispatch) => void
type FetchCallAnalyticsOne = (params: CallAnalyticsParams) => (dispatch: Dispatch) => void
type ClearExportedCallAnalytics = () => void
type SetFileDownloaded = (fileDownliaded: boolean, report: string, currentState: number) => void
export interface CallAnalyticsActions {
  fetchCallAnalyticsOne: FetchCallAnalyticsOne
  clearExportedCallAnalytics: ClearExportedCallAnalytics
  exportOneCallAnalytics: ExportCallAnalytics
  exportTwoCallAnalytics: ExportCallAnalytics
  exportThreeCallAnalytics: ExportCallAnalytics
  setFileDownloaded: SetFileDownloaded
  createReportCallAnalyticsOne: ExportCallAnalytics
  createReportCallAnalyticsTwo: ExportCallAnalytics
  createReportCallAnalyticsThree: ExportCallAnalytics
}

class CallAnalytics {
  public static FETCH_ONE_CALL_ANALYTICS_SUCCESS = "FETCH_ONE_CALL_ANALYTICS_SUCCESS"
  public static FETCH_ONE_CALL_ANALYTICS_TRIGGER = "FETCH_ONE_CALL_ANALYTICS_TRIGGER"
  public static FETCH_ONE_CALL_ANALYTICS_ERROR = "FETCH_ONE_CALL_ANALYTICS_ERROR"

  public static EXPORT_ONE_CALL_ANALYTICS_TRIGGER = "EXPORT_ONE_CALL_ANALYTICS_TRIGGER"
  public static EXPORT_ONE_CALL_ANALYTICS_SUCCESS = "EXPORT_ONE_CALL_ANALYTICS_SUCCESS"
  public static EXPORT_ONE_CALL_ANALYTICS_ERROR = "EXPORT_ONE_CALL_ANALYTICS_ERROR"

  public static EXPORT_TWO_CALL_ANALYTICS_TRIGGER = "EXPORT_TWO_CALL_ANALYTICS_TRIGGER"
  public static EXPORT_TWO_CALL_ANALYTICS_SUCCESS = "EXPORT_TWO_CALL_ANALYTICS_SUCCESS"
  public static EXPORT_TWO_CALL_ANALYTICS_ERROR = "EXPORT_TWO_CALL_ANALYTICS_ERROR"

  public static EXPORT_THREE_CALL_ANALYTICS_TRIGGER = "EXPORT_THREE_CALL_ANALYTICS_TRIGGER"
  public static EXPORT_THREE_CALL_ANALYTICS_SUCCESS = "EXPORT_THREE_CALL_ANALYTICS_SUCCESS"
  public static EXPORT_THREE_CALL_ANALYTICS_ERROR = "EXPORT_THREE_CALL_ANALYTICS_ERROR"

  public static CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_TRIGGER = "CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_TRIGGER"
  public static CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_SUCCESS = "CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_SUCCESS"
  public static CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_ERROR = "CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_ERROR"

  public static CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_TRIGGER = "CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_TRIGGER"
  public static CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_SUCCESS = "CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_SUCCESS"
  public static CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_ERROR = "CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_ERROR"

  public static CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_TRIGGER = "CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_TRIGGER"
  public static CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_SUCCESS = "CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_SUCCESS"
  public static CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_ERROR = "CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_ERROR"

  public static EXPORT_CALL_ANALYTICS_CLEAR = "EXPORT_CALL_ANALYTICS_CLEAR"

  public static SET_FILE_DOWNLOADED = "SET_FILE_DOWNLOADED"

  public static fetchCallAnalyticsOne: FetchCallAnalyticsOne =
    ({ organizationId, programId, dateFrom, dateTo }) =>
    async (dispatch) => {
      let res: AxiosResponse

      dispatch({
        type: CallAnalytics.FETCH_ONE_CALL_ANALYTICS_TRIGGER,
      })

      try {
        res = await API.get("/v2/calls/report-one", {
          params: {
            organizationId: organizationId || null,
            programId: programId || null,
            dateFrom: dateFrom || null,
            dateTo: dateTo || null,
          },
          headers: AuthHelper.getAdminHeaders(),
        })

        if (res.data.data) {
          dispatch({
            payload: res.data.data,
            type: CallAnalytics.FETCH_ONE_CALL_ANALYTICS_SUCCESS,
          })

          return
        }
      } catch (err) {
        const { response }: any = err

        dispatch({
          payload: response?.data?.message || "Oops!... Looks like an error occurred!",
          type: CallAnalytics.FETCH_ONE_CALL_ANALYTICS_ERROR,
        })

        return
      }
    }

  public static exportOneCallAnalytics: ExportCallAnalytics =
    ({ organizationId, programId, dateFrom, dateTo, extraFields }) =>
    async (dispatch) => {
      let res: AxiosResponse

      dispatch({
        type: CallAnalytics.EXPORT_ONE_CALL_ANALYTICS_TRIGGER,
      })

      try {
        res = await API.get("/v2/calls/report-one", {
          params: {
            organizationId: organizationId || null,
            programId: programId || null,
            dateFrom: dateFrom || null,
            dateTo: dateTo || null,
            ...(extraFields ? { extraFields } : {}),
          },
          headers: AuthHelper.getAdminHeaders(),
        })

        dispatch({
          type: CallAnalytics.EXPORT_ONE_CALL_ANALYTICS_SUCCESS,
          payload: getAnalyticsForExportReportOne(res.data.data, extraFields),
        })

        return
      } catch (err) {
        dispatch({
          type: CallAnalytics.EXPORT_ONE_CALL_ANALYTICS_ERROR,
        })

        return
      }
    }

  public static exportTwoCallAnalytics: ExportCallAnalytics =
    ({ organizationId, programId, dateFrom, dateTo, extraFields }) =>
    async (dispatch) => {
      let res: AxiosResponse

      dispatch({
        type: CallAnalytics.EXPORT_TWO_CALL_ANALYTICS_TRIGGER,
      })

      try {
        res = await API.get("/v2/calls/report-two", {
          params: {
            organizationId: organizationId || null,
            programId: programId || null,
            dateFrom: dateFrom || null,
            dateTo: dateTo || null,
            ...(extraFields ? { extraFields } : {}),
          },
          headers: AuthHelper.getAdminHeaders(),
        })

        dispatch({
          type: CallAnalytics.EXPORT_TWO_CALL_ANALYTICS_SUCCESS,
          payload: getAnalyticsForExportReportTwo(res.data.data, extraFields),
        })

        return
      } catch (err) {
        dispatch({
          type: CallAnalytics.EXPORT_TWO_CALL_ANALYTICS_ERROR,
        })

        return
      }
    }

  public static exportThreeCallAnalytics: ExportCallAnalytics =
    ({ organizationId, programId, dateFrom, dateTo, extraFields }) =>
    async (dispatch) => {
      let res: AxiosResponse

      dispatch({
        type: CallAnalytics.EXPORT_THREE_CALL_ANALYTICS_TRIGGER,
      })

      try {
        res = await API.get("/v2/calls/report-three", {
          params: {
            organizationId: organizationId || null,
            programId: programId || null,
            dateFrom: dateFrom || null,
            dateTo: dateTo || null,
            ...(extraFields ? { extraFields } : {}),
          },
          headers: AuthHelper.getAdminHeaders(),
        })

        dispatch({
          type: CallAnalytics.EXPORT_THREE_CALL_ANALYTICS_SUCCESS,
          payload: getAnalyticsForExportReportThree(res.data.data, extraFields),
        })

        return
      } catch (err) {
        dispatch({
          type: CallAnalytics.EXPORT_THREE_CALL_ANALYTICS_ERROR,
        })

        return
      }
    }

  public static createReportCallAnalyticsOne: ExportCallAnalytics =
    ({ organizationId, programId, dateFrom, dateTo, extraFields }) =>
    async (dispatch) => {
      dispatch({
        type: CallAnalytics.CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_TRIGGER,
      })

      try {
        const now = new Date().getTime()
        const { userId } = AuthHelper.getUserAuth()
        const payload = pickBy(
          {
            organizationId: organizationId || null,
            requestedById: userId,
            module: ReportRequestModuleEnum.CALLSTATS1,
            filters: {
              startDate: dateFrom ? new Date(dateFrom).getTime() : now,
              endDate: dateTo ? new Date(dateTo).getTime() : now,
              tz: dayjs.tz.guess(),
              programId: programId || null,
              params: {
                fields: extraFields || [],
              },
            },
          },
          (value) => value !== null,
        )
        await API.post("/v2/reportrequest/create", payload, {
          headers: AuthHelper.getAdminHeaders(),
        })

        dispatch({
          type: CallAnalytics.CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_SUCCESS,
        })

        toast("Report request created successfully, you will be notified when it's ready for download", { type: "success", autoClose: false })
        return
      } catch (err) {
        dispatch({
          type: CallAnalytics.CREATE_REPORT_REQUEST_ONE_CALL_ANALYTICS_ERROR,
        })
        toast("Error creating report request, try again later", { type: "error" })
      }
    }
  public static createReportCallAnalyticsTwo: ExportCallAnalytics =
    ({ organizationId, programId, dateFrom, dateTo, extraFields }) =>
    async (dispatch) => {
      dispatch({
        type: CallAnalytics.CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_TRIGGER,
      })

      try {
        const now = new Date().getTime()
        const { userId } = AuthHelper.getUserAuth()
        const payload = pickBy(
          {
            organizationId: organizationId || null,
            requestedById: userId,
            module: ReportRequestModuleEnum.CALLSTATS2,
            filters: {
              startDate: dateFrom ? new Date(dateFrom).getTime() : now,
              endDate: dateTo ? new Date(dateTo).getTime() : now,
              tz: dayjs.tz.guess(),
              programId: programId || null,
              params: {
                fields: extraFields || [],
              },
            },
          },
          (value) => value !== null,
        )
        await API.post("/v2/reportrequest/create", payload, {
          headers: AuthHelper.getAdminHeaders(),
        })

        dispatch({
          type: CallAnalytics.CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_SUCCESS,
        })

        toast("Report request created successfully, you will be notified when it's ready for download", { type: "success", autoClose: false })
        return
      } catch (err) {
        dispatch({
          type: CallAnalytics.CREATE_REPORT_REQUEST_TWO_CALL_ANALYTICS_ERROR,
        })
        toast("Error creating report request, try again later", { type: "error" })
      }
    }
  public static createReportCallAnalyticsThree: ExportCallAnalytics =
    ({ organizationId, programId, dateFrom, dateTo, extraFields }) =>
    async (dispatch) => {
      dispatch({
        type: CallAnalytics.CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_TRIGGER,
      })

      try {
        const now = new Date().getTime()
        const { userId } = AuthHelper.getUserAuth()
        const payload = pickBy(
          {
            organizationId: organizationId || null,
            requestedById: userId,
            module: ReportRequestModuleEnum.CALLSTATS3,
            filters: {
              startDate: dateFrom ? new Date(dateFrom).getTime() : now,
              endDate: dateTo ? new Date(dateTo).getTime() : now,
              tz: dayjs.tz.guess(),
              programId: programId || null,
              params: {
                fields: extraFields || [],
              },
            },
          },
          (value) => value !== null,
        )
        await API.post("/v2/reportrequest/create", payload, {
          headers: AuthHelper.getAdminHeaders(),
        })

        dispatch({
          type: CallAnalytics.CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_SUCCESS,
        })

        toast("Report request created successfully, you will be notified when it's ready for download", { type: "success", autoClose: false })
        return
      } catch (err) {
        dispatch({
          type: CallAnalytics.CREATE_REPORT_REQUEST_THREE_CALL_ANALYTICS_ERROR,
        })
        toast("Error creating report request, try again later", { type: "error" })
      }
    }

  public static setFileDownloaded: SetFileDownloaded = (fileDownloaded, report, currentStatus) => ({
    type: CallAnalytics.SET_FILE_DOWNLOADED,
    payload: {
      fileDownloaded,
      currentStatus,
      report,
    },
  })

  public static clearExportedCallAnalytics = () => (dispatch: Dispatch) => {
    dispatch({
      type: CallAnalytics.EXPORT_CALL_ANALYTICS_CLEAR,
    })
  }
}

export default CallAnalytics
