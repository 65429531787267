// tslint:disable: no-any object-literal-sort-keys
import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"

import Auth, { AuthActions } from "../actions/auth"
import { AuthStore } from "../reducers/authReducer"
import { GlobalStore } from "../reducers"

type WithAuthType = (ComposedComponent: React.ComponentType<any>) => React.ComponentType<any & AuthStore & AuthActions>

type MapStateToProps = (state: GlobalStore) => AuthStore

const withAuth: WithAuthType = (ComposedComponent: React.ComponentType<any>) => {
  class WithAuth extends React.Component<any & AuthStore & AuthActions> {
    public render(): React.ReactNode {
      return <ComposedComponent {...this.props} />
    }
  }

  const mapStateToProps: MapStateToProps = ({ authUser }: GlobalStore) => ({
    ...authUser,
  })

  const mapDispatchToProps: AuthActions & { push: any } = {
    push,
    createPassword: Auth.createPassword,
    changePassword: Auth.changePassword,
    forgotPassword: Auth.forgotPassword,
    login: Auth.login,
    logout: Auth.logout,
    resetPassword: Auth.resetPassword,
    updateTosAccepted: Auth.updateTosAccepted,
    getUserStatus: Auth.getUserStatus,
    resetUpdatePasswordError: Auth.resetUpdatePasswordError,
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithAuth)
}

export type AuthProps = AuthActions & AuthStore & { push: any }
export default withAuth
