import { BroadcastType } from "../constants/broadcast"

export type Program = {
  id: string
  name: string
  phone: string
  type?: string
  organizationId: string
  isDefault: boolean
}

export type CreateProgram = {
  name: string
  phone?: string
  type?: string
  organizationId?: string
  isDefault: boolean
}

export enum CreateProgramStatus {
  PRISTINE = 0,
  SUBMITTING,
  SUCCESS,
  ERROR,
}

export type ProgramRecipientContactType = "phone" & BroadcastType

export enum ProgramRecipientContactTypeEnum {
  PHONE = "phone",
  EMAIL = "email",
}

export enum ProgramRecipientTypeEnum {
  PROGRAM = "program",
  PROGRAM_CALLER = "program_caller",
  PROGRAM_RECIPIENT = "program_recipient",
  STATUS = "status",
  STATUS_CALLER = "status_caller",
  STATUS_RECIPIENT = "status_recipient",
  PERSON = "person",
}

export type ProgramRecipientType = ProgramRecipientTypeEnum.PERSON | ProgramRecipientTypeEnum.PROGRAM

export interface ProgramRecipientDto {
  id: string
  name: string
  type?: ProgramRecipientType
  roleName?: string
  contactType?: ProgramRecipientContactType
  personOrganizationProgramRoleId?: string
}
