// tslint:disable: no-any
import { RoleType } from "../constants/roles"
import { UserProfileNameEnum, UserProfileTypeEnum } from "../constants/views"
import { Organization, PersonOrganizationRoleProgramCustomFieldValue } from "./OrganizationType"

export interface Person {
  createdAt: string
  deletedAt?: string
  email?: string
  firstName: string
  id: string
  name?: string
  lastName: string
  notes?: string
  phone: string
  phoneAlternate?: string
  program?: any
  preferredName?: string
  updatedAt?: string
  organizationId?: string
  userName?: string
  personDetailId?: string
  optedOut?: string[] | null
  personDetail?: PersonDetail
  externalPersonId?: string | null
}

// PERSON_ORGANIZATION
export interface PersonOrganization {
  id: string
  active?: boolean
  matched?: boolean
  callee: {
    id: string
    person: Person
  }
  caller: {
    id: string
    person: Person
  }
  organization?: Organization
  organizationId?: string
  person: Person
  personId: string
  pin: string
  program: Program
  programId: string
  role: Role
  roleId: string
  matches?: Person[]
  externalPersonId?: string | null
  organizationPersonStatusId?: string
  organizationPersonStatus?: OrganizationPersonStatus
  surveyOptOuts?: string[]
  customFields?: any | PersonOrganizationRoleProgramCustomFieldValue[]
  callListQueueNames?: string[]
}

export interface OrganizationPersonStatus {
  id: string
  name: string
  description?: string
  isDefault?: boolean
  organizationId?: string
}

export type CreateOrganizationPersonStatus = {
  name: string
  description: string
  organizationId?: string
}

export enum CreateOrganizationPersonStatusStatus {
  PRISTINE = 0,
  SUBMITTING,
  SUCCESS,
  ERROR,
}

export interface CreatePersonOrganization {
  active?: boolean
  pin?: string | null
  userName?: string | null
  password?: string | null
  personId?: string | null
  roleId?: string | null
  programId?: string | null
  organizationId?: string | null
  firstName: string | null
  lastName: string | null
  phone: string
  phoneAlternate?: string | null
  email?: string | null
  preferredName?: string | null
  program?: string | null
  organizationPersonStatusId?: string | null
  roleType?: string | null
  personDetailId?: string | null
  personDetail?: PersonDetail | null
  optedOut?: string[] | null
  surveyOptOuts?: string[] | null
  customFields?: any | PersonOrganizationRoleProgramCustomFieldValue[]
}

export interface PersonDetail {
  id?: string
  zipCode: string | null
  dateOfBirth: string | null
  gender: PersonDetailGender
  preferredLanguage: string | null
  hobbies: string | null
  phoneCallBehavior: PersonDetailCallBehavior
  preferredTimeForCall: PersonDetailTimeForCall[] | null
  preferredMethodOfCommunication: PersonDetailPreferredMethodOfCommunication[] | null
  phoneType: PersonDetailPhoneType
  phoneAlternateType: PersonDetailPhoneType
}

export interface EditPersonOrganizations {
  active?: boolean
  email?: string | null
  firstName?: string
  lastName?: string
  phone?: string
  phoneAlternate?: string
  notes?: string
  programId?: string
  program?: any
  preferredName?: string
  pin?: string | null
  organizationId?: string
  roleId?: string
  personId?: string
  userName?: string
  personDetailId?: string | null
  personDetail?: PersonDetail | null
  externalPersonId?: string | null
  organizationPersonStatusId?: string | null
  optedOut?: string[] | null
  surveyOptOuts?: string[] | null
  customFields?: any | PersonOrganizationRoleProgramCustomFieldValue[]
}

// Export data types
export interface ExportedPersonOrganization {
  firstName: string
  lastName: string
  preferredName?: string
  phone: string
  phoneAlternate?: string
  email?: string
  matched?: string
  organization?: string
  program?: string
  createdAt: string
  updatedAt?: string
  pin: string | null
  matches?: string
  active?: string
}

export interface Header {
  label: string
  key: string
}

export interface ExportedPersonOrganizations {
  headers: Header[] | []
  data: ExportedPersonOrganization[] | []
}

export interface Role {
  id: string
  name: RoleType
}

export type PersonRoleType = "caller" | "recipient"

export interface Program {
  id: string
  name: string
  isDefault: boolean
  phone: string
  organizationId: string
}

export interface PersonOrganizationProfile {
  id: string
  active: boolean
  name: UserProfileNameEnum
  type: UserProfileTypeEnum
}

export interface NamedProfile {
  id: string
  name: string
}

export type PersonDetailTimeForCall = "Morning" | "Afternoon" | "Evening" | string | null
export type PersonDetailGender = "Male" | "Female" | "Other" | string | null
export type PersonDetailCallBehavior = "Talkative" | "Somewhat Talkative" | "Not Very Talkative" | string | null
export type PersonDetailPhoneType = "Mobile" | "Landline" | string | null
export type PersonDetailPreferredMethodOfCommunication = "Email" | "SMS" | "Voice" | string | null
