// tslint:disable: max-line-length
/*
  Language Texts
  ==============

  Table of Contents
  -----------------

  01.General
  02.User
  03.Login
  04.Menu
  05.Pages
  06.Person
  07.Organization
  08.Campaign
  09.Onboarding
  10.Tooltip
  11.CallLogs
  12.Program
  13.Call Analytics
  14.My account
  15.Date Filter
  16.Person Detail
  17.Broadcast
  18.New Broadcast
  19.Broadcast Detail
  20.Surveys
  21.Organization Person Status
  22.Notes List
  23.Outreach Queue
  24.Self Signup
*/

export default {
  /* 01.General */
  "general.anonymize-export": "Anonymize Export",
  "general.back": "Back",
  "general.cancel": "Cancel",
  "general.clear-filters": "Clear Filter",
  "general.close": "Close",
  "general.contact-info": "Have questions about our service or need help with you account?",
  "general.contact-subtitle": "Contact Us",
  "general.contacts": "Contacts",
  "general.copyright": "Nimble.la © 2019 All Rights Reserved.",
  "general.download": "Download file",
  "general.download-complete": "Download complete",
  "general.error": "Error",
  "general.export": "Export",
  "general.create-report": "Create Report",
  "general.export-call-analytics": "Export Call Analytics",
  "general.anonymous-export": "Anonymous Export",
  "general.export-outreach": "Export Onboarding",
  "general.export-recipient": "Export Recipients",
  "general.export-caller": "Export Callers",
  "general.export-inactive": "Export Inactive",
  "general.mail": "",
  "general.next": "Next",
  "general.processing": "Processing",
  "general.submit": "Submit",
  "general.editable-label.on-hover-message": "Click on the label to edit it",
  "general.export-survey-disabled": "The button is disabled, as it does not meet the requirements for exporting",
  "general.permission.action-denied": "You cannot perform this action",
  "general.export-select-extra-fields": "Please, select the extra fields to be included in the export.",

  /* 02.User */
  "user.call-logs": "Call Logs",
  "user.confirm-new-password": "Confirm New Password",
  "user.confirm-password": "Confirm password",
  "user.create-descriptive-text": "Please complete the fields to set your new password",
  "user.create-password": "Create your new password",
  "user.email": "E-mail",
  "user.forgot-password": "Forgot Password",
  "user.forgot-password-question": "Forgot password?",
  "user.if-not-member": "If you are not a member, please ",
  "user.invalid-password": "Wrong password",
  "user.new-password": "New password",
  "user.new-password-max": "128 characters max",
  "user.old-password": "Old Password",
  "user.password": "Password",
  "user.please-register": "register",
  "user.reset": "Reset your password",
  "user.reset-descriptive-text": "Please enter the email address associated with your Callhub account.",
  "user.reset-descriptive-mfa-text": "Please verify your identity Callhub account username and number.",
  "user.reset-password-button": "SUBMIT",
  "user.reset-password-send-message": "We'll send you a link to use to reset your password. If you have not received the email after a few minutes, please check your spam folder",
  "user.reset-password-complete-message-title": "Password Updated!",
  "user.reset-password-complete-message": "You may proceed to login",
  "user.reset-password-mfa-message": "First, let's verify the account belongs to you and then we can reset your password",
  "user.logout": "Logout",
  "user.use-credentials": "Please use your credentials to login.",
  "user.username": "Username",
  "user.switch-organization": "Switch organization",
  "user.forgot-password-phone": "Phone",
  "user.forgot-password-phone-digits": "Last 4 digits of your phone number",
  "user.mfa-select": "Verify your identity using SMS or recieve a call?",
  "user.mfa-verify": "Please enter your verification code",
  "user.password-change-success": "Password successfully changed",

  /* 03.Login */
  "login.button": "LOGIN",
  "login.email-invalid": "Invalid e-mail",
  "login.email-required": "E-mail required",
  "login.error-message": "Invalid credentials",
  "login.password-confirm-required": "Confirm Password required",
  "login.password-length": "Password must be at least 12 characters long",
  "login.password-match": "Passwords don't match",
  "login.password-required": "Password required",
  "login.title": "Login",
  "login.username-required": "Username required",
  "login.welcome": "Welcome!",
  "login.select-organization": "Select an organization to continue",
  "login.select-organization-continue": "CONTINUE",
  "login.select-organization-error": "Please select an organization",

  /* 04.Menu */
  "menu.account": "Account",
  "menu.app": "Home",
  "menu.campaigns": "Campaigns",
  "menu.contacts": "Contacts",
  "menu.call-analytics": "Call Analytics",
  "menu.call-logs": "Call logs",
  "menu.surveys": "Surveys",
  "menu.outreach": "Outreach Queue",
  "menu.dashboard": "Dashboard",
  "menu.notes": "Notes",
  "menu.broadcasts": "Broadcasts",
  "menu.edit-profile": "Edit",
  "menu.list": "List",
  "menu.my-account": "My account",
  "menu.organization": "Organization",
  "menu.organizations": "Organizations",
  "menu.recipient": "Recipients",
  "menu.recipients": "Recipients",
  "menu.users": "Users",
  "menu.caller": "Callers",
  "menu.callers": "Callers",
  "menu.welcome": "Welcome",

  /* 05.Pages */
  "pages.button-accept": "Accept",
  "pages.button-delete-outreach": "DELETE ONBOARDING RECIPIENT",
  "pages.button-delete-recipient": "DELETE RECIPIENT",
  "pages.button-convert-recipient": "CONVERT TO RECIPIENT",
  "pages.button-disable-profile": "DISABLE PROFILE",
  "pages.button-enable-profile": "ENABLE PROFILE",
  "pages.button-return-to-onboarding": "RETURN TO ONBOARDING",
  "pages.button-delete-caller": "DELETE CALLER",
  "pages.button-next": "Next",
  "pages.button-back": "Back",
  "pages.create-success": "Created Successfully",
  "pages.edit-success": "Success",
  "pages.email-sent-success": "We will send password reset instructions to the user if applicable.",
  "pages.email-sent-error": "Invalid email address. Please, try another",
  "pages.error": "Error",
  "pages.error-403": "403",
  "pages.error-404": "404",
  "pages.error-500": "500",
  "pages.error-code": "Error code",
  "pages.error-magic": "MAGIC IS IN THE DETAILS",
  "pages.error-magic-indeed": "Yes, it is indeed!",
  "pages.error-title": "Ooops... looks like an error occurred!",
  "pages.error-subtitle": "Page not found",
  "pages.forbidden-subtitle": "Something went wrong",
  "pages.go-back": "Back",
  "pages.go-back-home": "Go Back",
  "pages.match-success": "Matched Successfully",
  "pages.complete-survey-success": "Survey created, click the button below to answer",
  "pages.password-change-success": "Password successfully changed",

  /* 06.Person */
  "person.actions": "Actions",
  "person.queues": "Queues",
  "person.active": "Yes",
  "person.age": "Age",
  "person.create": "Create",
  "person.confirm-password": "Confirm password",
  "person-confirm-password-required": "Confirm password is required",
  "person.edit-pin-only-numbers": "Pin must be only numbers",
  "person.edit-recipient-success-message": "Recipient updated successfully",
  "person.edit-caller-success-message": "Caller updated successfully",
  "person.edit-success-message": "Updated successfully",
  "person.edit-error-message": "Error! Could not edit",
  "person.edit-title": "Edit",
  "person.email": "Email",
  "person.empty-recipient": "No recipients found",
  "person.empty-recipients": "No recipients found",
  "person.empty-caller": "No callers found",
  "person.empty-callers": "No callers found",
  "person.empty-users": "No users available",
  "person.error-blank-space": "This field cannot be empty",
  "person.error-detail": "Profile information is not available",
  "person.error-email-format": "Invalid format",
  "person.error-email-required": "Email is required",
  "person.error-first-name-required": "Name is required",
  "person.error-first-name-max-length": "255 characters max",
  "person.error-last-name-required": "Last Name is required",
  "person.error-last-name-max-length": "255 characters max",
  "person.error-match-recipients-required": "Recipient is required",
  "person.error-match-callers-required": "Caller is required",
  "person.error-min-pin-numbers": "Pin must be at least 4 numbers long",
  "person.error-password-digit": "Password must have at least one digit",
  "person.error-password-lowercase": "Password must have at least one lowercase letter",
  "person.error-password-min": "Password must be at least 12 characters long",
  "person.error-password-required": "Password is required",
  "person.error-password-special-symbol": "Password must have at least a symbol",
  "person.error-password-uppercase": "Password must have at least one uppercase letter",
  "person.error-phone-code-invalid": "US phone number must start with 1",
  "person.error-phone-invalid": "Invalid phone",
  "person.error-phone-min": "Phone must be a valid number",
  "person.error-phone-required": "Phone is required",
  "person.error-pin-max": "Pin must be 4 digits long",
  "person.error-pin-required": "Pin is required",
  "person.error-preferred-name-required": "Preferred Name is required",
  "person.error-preferred-name-max-length": "255 characters max",
  "person.error-profile": "User not found",
  "person.error-program-required": "Program is required",
  "person.error-user-name-length": "50 characters max",
  "person.error-user-name-required": "Username is required",
  "person.error-user-name-spaces": "Blank spaces are not allowed",
  "person.first-name": "First name",
  "person.id": "ID",
  "person.inactive": "No",
  "person.label-match-recipient": "Select recipients",
  "person.label-match-caller": "Select callers",
  "person.last-name": "Last name",
  "person.loading": "Loading",
  "person.match-placeholder-recipient": "No recipient selected",
  "person.match-placeholder-caller": "No callers selected",
  "person.match-recipients": "Select a Recipient",
  "person.match-callers": "Select a Caller",
  "person.matched": "Matched",
  "person.matches": "Matches",
  "person.name": "Name",
  "person.notes": "Notes",
  "person.organization": "Organization",
  "person.password": "Password",
  "person.password-match-error": "Passwords do not match",
  "person.preferred-name": "Preferred Name",
  "person.phone": "Primary Phone",
  "person.phone-alternate": "Alternate Phone",
  "person.pin": "Pin",
  "person.pin-generate": "Generate",
  "person.pin-only-numbers": "Pin must be only numbers",
  "person.program": "Program",
  "person.recipient-edit-title": "Edit Recipient",
  "person.status": "Active",
  "person.organization-status": "Status",
  "person.organization-program": "Program",
  "person.organization-role": "Role",
  "person.organization-status.placeholder": "Select one status",
  "person.title-add-organization-admin": "Add Organization Admin",
  "person.title-add-program": "Add Program",
  "person.title-add-status": "Add Status",
  "person.title-view-config": "Configurations & Features",
  "person.title-match-recipients": "Match Recipients",
  "person.title-complete-survey": "Complete A Survey",
  "person.title-match-callers": "Match Callers",
  "person.title-terms-of-service": "Terms of Service and Acceptable Use Policy",
  "person.unmatched": "Unmatched",
  "person.user-name": "Username",
  "person.external-person-id": "External Person ID",
  "person.caller-edit-title": "Edit Caller",
  "person-org-complete-survey-form-select-label": "Select survey",
  "person-org-complete-survey-form-select-placeholder": "Loading...",

  /* 07.Organization */
  "organization.is-external": "External",
  "organization.external-id": "External ID",
  "organization.external-id.max-length": "At least 2 characters",
  "organization.external-id.min-length": "255 characters max",
  "organization.external-id.required": "When External switch is enabled, this field is required",
  "organization.external-id.empty": "When External switch is disabled, this field must be empty",
  "organization.active": "Active",
  "organization.active.yes": "Yes",
  "organization.active.no": "No",
  "organization.add-outreach": "Add To Onboarding",
  "organization.add-recipient": "Add Recipient",
  "organization.add-title": "Add Organization",
  "organization.add-caller": "Add Caller",
  "organization.address-city": "City",
  "organization.address-line-2": "Address line 2",
  "organization.address-line-2-optional": " (optional)",
  "organization.address-line-2-placeholder": "Floor, Door number, other.",
  "organization.address-state": "State",
  "organization.address-state-short": "State short",
  "organization.address-street": "Street",
  "organization.address-zipcode": "Zipcode",
  "organization.contact-phone": "ORGANIZATION PHONE",
  "organization.contact-phone-form": "Organization phone",
  "organization.error-phone-required": "Phone is required",
  "organization.csv-instructions": "You can see the full instructions ",
  "organization.csv-instructions-here": "here",
  "organization.download-file": "You don't have a csv file?",
  "organization.download-first-instruction": "1) Download the CallHub CSV",
  "organization.download-second-instruction": "2) Format the information of the Recipients and Callers to match the template",
  "organization.download-third-instruction": "3) Upload the formatted file using the button below",
  "organization.edit": "Edit",
  "organization.edit-city-required": "City is required",
  "organization.edit-email-required": "Email is required",
  "organization.edit-email-valid": "Invalid email address",
  "organization.edit-name-max-length": "255 characters max",
  "organization.edit-name-required": "Organization name is required",
  "organization.edit-primary-contact-max-length": "255 characters max",
  "organization.edit-primary-contact-required": "Primary contact is required",
  "organization.edit-program-required": "Program is required",
  "organization.edit-state-required": "State is required",
  "organization.edit-state-short-required": "State short is required",
  "organization.edit-street-required": "Organization street is required",
  "organization.edit-callhub-line": "CallHub Line is required",
  "organization.edit-address-street-max-length": "50 characters max",
  "organization.edit-zipcode-as-much-numbers": "Zipcode must be as much 5 characters",
  "organization.edit-zipcode-at-least-numbers": "Zipcode must be at least 5 characters",
  "organization.edit-zipcode-only-numbers": "Zipcode must be only numbers",
  "organization.edit-zipcode-required": "Zipcode is required",
  "organization.email": "Email",
  "organization.email-detail": "EMAIL",
  "organization.email-invitations-sent": "Invitations sent",
  "organization.email-invite": "Invite callers",
  "organization.email-send-invitations": "Send invitations",
  "organization.email-sending-invitations": "Sending",
  "organization.error-blank-space": "This field cannot be empty",
  "organization.error-list": "No organizations found",
  "organization.error-message": "Organization Info is not available.",
  "organization.error-profile": "Organization not found",
  "organization.file-processing": "Processing",
  "organization.file-selected": "File selected:",
  "organization.id": "ID",
  "organization.inactive": "Inactive",
  "organization.instructive-video": "Instructive video",
  "organization.join-date": "DATE JOINED",
  "organization.list-create-button": "Add organization",
  "organization.loading": "Loading",
  "organization.location-detail": "LOCATION",
  "organization.name": "Organization name",
  "organization.no-outreach": "No onboarding recipients available",
  "organization.no-recipient": "No recipients available",
  "organization.no-caller": "No callers available",
  "organization.no-inactive": "No results for inactive",
  "organization.phone-number": "CALLHUB LINE",
  "organization.phone-number-form": "CallHub Line",
  "organization.point-of-contact": "Point of contact",
  "organization.point-of-contact-detail": "POINT OF CONTACT",
  "organization.primary-contact": "Primary contact",
  "organization.program-name": "Name",
  "organization.program-delete": "Delete program",
  "organization.program-set-default": "Set default program",
  "organization.program-default": "Default program",
  "organization.status-name": "Status name",
  "organization.status-description": "Status description",
  "organization.programs": "Program(s)",
  "organization.recipients": "Recipients",
  "organization.outreaches": "Onboarding",
  "organization.status": "Status",
  "organization.select-file": "Select file",
  "organization.total-recipient": "TOTAL RECIPIENTS",
  "organization.total-users": "TOTAL USERS",
  "organization.total-caller": "TOTAL CALLERS",
  "organization.upload-csv": "Upload CSV file",
  "organization.upload-outreach": "Upload Onboarding",
  "organization.upload-recipient": "Upload Recipients",
  "organization.upload-caller": "Upload Callers",
  "organization.callers": "Callers",
  "organization.status-set-default": "Set default status",
  "organization.status-default": "Default status",
  "organization.status-delete": "Delete status",
  "organization.status-set-default-locked": "System predefined status",

  /* 08.Campaign */
  "campaign.clear-button": "Clear",
  "campaign.form-tag": "Input Callers IDs",
  "campaign.send-button": "Send",
  "campaign.success-message": "Emails successfully sent!",
  "campaign.title": "Callers Welcome Emails",

  /* 09.Onboarding */
  "onboarding.accept-terms-of-service": "I accept CallHub Terms of Service and Acceptable Use Policy",
  "onboarding.call-hub": "CallHub",
  "onboarding.create-new-password": "Please, create a new password to start your new account",
  "onboarding-caregivers-text": "Protecting the safety and health of our vulnerable population and caregivers.",
  "onboarding.download-csv": "Download CSV",
  "onboarding.download-instructions": "Download instructions",
  "onboarding-download-first-instruction": "1) Download the CallHub CSV",
  "onboarding-download-second-instruction": "2) Format the information of the Recipients and Callers to match the template",
  "onboarding-download-third-instruction": "3) Please upload the completed CSV on the next page",
  "onboarding.file-selected": "File selected: ",
  "onboarding.invitation": "You've been invited to join Call Hub!",
  "onboarding-no-file-selected": "No file selected. Continue without uploading a CSV?",
  "onboarding.password-error": "Something went wrong, try again",
  "onboarding.password-length": "Must be at least 8 characters long",
  "onboarding.password-not-match": "Passwords do not match",
  "onboarding.password-success": "Password successfully updated.",
  "onboarding.processing-csv": "Processing",
  "onboarding.step": "Step",
  "onboarding.success": "Success!",
  "onboarding.select-file": "Select file",
  "onboarding.select-file-alternative": "Please, select your CSV file to upload in case that you have one.",
  "onboarding.terms-of-service": "Terms of Service and Acceptable Use Policy",
  "onboarding.watch-the-video": "Watch the video to learn how to download CSV and input your information",

  /* 10.Tooltips */
  "tooltip-add-organization": "Create a new organization",
  "tooltip-add-organization-admin": "Add organization admin",
  "tooltip-add-program-organization": "Add program",
  "tooltip-add-status-organization": "Add status",
  "tooltip-view-config-organization": "Configurations",
  "tooltip-create-user": "Create a new user",
  "tooltip-help-terms-of-service": "Please read the file and accept the Terms of Service and Acceptable Use Policy to continue",
  "tooltip-edit-organization": "Edit organization's data",
  "tooltip-complete-survey": "Complete A Survey",
  "tooltip-edit-caller": "Edit caller's data",
  "tooltip-edit-outreach": "Edit onboarding recipient's data",
  "tooltip-edit-recipient": "Edit recipient's data",
  "tooltip-export-call-logs": "Export all call logs",
  "tooltip-export-call-analytics": "Export all call analytics",
  "tooltip-export-call-analytics-one": "Export CallStats 1",
  "tooltip-export-call-analytics-two": "Export CallStats 2",
  "tooltip-export-call-analytics-three": "Export CallStats 3",
  "tooltip-export-caller": "Export all callers",
  "tooltip-export-recipient": "Export all recipients",
  "tooltip-person-match": "Create a new match",
  "tooltip-upload-csv": "Create multiple callers or recipients with a comma separated values (CSV) file",
  "tooltip-invite-callers": "Invite callers via email",
  "tooltip-delete-orgAdmin": "Organization admins can not be deleted",
  "tooltip-add-new-note": "Add new note to the user",
  "tooltip-opt-out-sms-disabled": "Send STOP word for opting out and START word for opting back in",
  "tooltip-sortable-column": "Click to change order column and direction",

  /* 11.CallLogs */
  "call-logs.clear-filters": "Clear Filter",
  "call-logs.date-time": "DATE & TIME",
  "call-logs.invalid-date-time": "Expected format: mm/dd/yyyy",
  "call-logs.duration": "DURATION",
  "call-logs.filter-by-organizations": "Filter by Organization",
  "call-logs.filter-by-programs": "Filter by Programs",
  "call-logs.filter-by-types": "Filter by Types",
  "call-logs.from": "FROM",
  "call-logs.loading": "Loading",
  "call-logs.not-found": "No Call logs found",
  "call-logs.notes": "Notes",
  "call-logs.organization": "ORGANIZATION",
  "call-logs.status": "STATUS",
  "call-logs.to": "TO",
  "call-logs.caller-survey": "SURVEY",
  "call-logs.outcome": "OUTCOME",

  /* 12.Program */
  "program.error-name-max-length": "50 characters max",
  "program.error-name-min-length": "3 characters min, empty spaces are not allowed",
  "program.error-name-required": "Name is required",

  /* 13.Call Analytics */
  "call-analytics.clear-filters": "Clear Filter",
  "call-analytics.date-time": "DATE & TIME",
  "call-analytics.filter-by-programs": "Filter By Programs",
  "call-analytics.filter-by-organizations": "Filter by Organization",
  "call-analytics.loading": "Loading",
  "call-analytics.not-found": "No Call Analytics found",
  "call-analytics.notes": "Notes",
  "call-analytics.export.button": "Export",
  "call-analytics.report-one": "CALLSTATS 1",
  "call-analytics.report-one.description":
    "Summarizes high level data that easily shows the scale and scope of the program. Organizations often use this report to update their leadership with key high level data points such as total number of people receiving calls and total number of minutes of calls.",
  "call-analytics.report-two": "CALLSTATS 2",
  "call-analytics.report-two.description":
    "Summarizes caller data including the total duration of calls made to each recipient. Organizations often use this report gain a high-level view on the number and duration of connections happening between each match.",
  "call-analytics.report-three": "CALLSTATS 3",
  "call-analytics.report-three.description":
    "The most detailed report. It summarizes the total calls for each caller and each recipient. It can be sorted by type (Caller or Recipient) to gather specific information at a glance including call time, total calls made, date of most recent call, and current matches.",
  "call-analytics.report-four": "CALLSTATS 4",
  "call-analytics.tabs.first-title": "Analytics Reports",
  "call-analytics.tabs.second-title": "Generated Reports",

  /* 14.My account */
  "my-account.password-expired.notification-message": "You need to update your password! (It needs to be updated every 90 days)",
  "my-account.password-expired.notification-title": "NOTIFICATION",
  "my-account.password-requirement.confirm-password-must-match": "Password must match",
  "my-account.password-requirement.contains-user-info": "Password must not contain portions of username or fullname",
  "my-account.password-requirement.has-lowercase": "At least one lower case letter",
  "my-account.password-requirement.has-minimum-length": "A minimum of 12 characters",
  "my-account.password-requirement.has-number": "At least one number (0-9)",
  "my-account.password-requirement.has-special-symbol": "At least one special symbol (!@#$%^&*)",
  "my-account.password-requirement.has-uppercase": "At least one upper case letter",
  "my-account.password-requirement.invalid-characters": "Invalid Characters",
  "my-account.password-requirement.not-match-all-reqs": "Password doesn't match all requirements",
  "my-account.password-requirement.title": "Your password must be:",
  "my-account.password.title": "Password",
  "my-account.password.update-message": "Passwords need to be updated every 90 days",
  "my-account.password.update-password-submit": "Update Password",
  "my-account.title": "My Account",

  /* 15.Date Filter */
  "date-filter.title": "Presets",
  "date-filter.last_24_hours": "Last 24 Hours",
  "date-filter.last_7_days": "Last 7 Days",
  "date-filter.last_30_days": "Last 30 Days",
  "date-filter.last_60_days": "Last 60 Days",
  "date-filter.last_90_days": "Last 90 Days",

  /* 16. Person Notes */
  "note-person.note": "Note",
  "note-person.author": "Author",
  "note-person.createdAt": "Creation Date",
  "note-person.title-add-note-person": "Add new note to the user",
  "note-person.title-add-note-person-survey": "Add new note to survey",
  "note-person.error-note-max-length": "500 characters max",
  "note-person.error-note-required": "Note is required",

  /* 17. Person Detail */
  "person-detail.gender": "Gender",
  "person-detail.date-of-birth": "Date of Birth",
  "person-detail.zipcode": "Zip Code",
  "person-detail.zipcode-error-only-digits": "Zip Code must be only numbers",
  "person-detail.zipcode-error-length": "Must have 5 digits",
  "person-detail.phone-call-behavior": "Caller Behavior",
  "person-detail.preferred-time-for-call": "Preferred Time for Call",
  "person-detail.preferred-method-of-communication": "Preferred Method of Communication",
  "person-detail.hobbies": "Hobbies",
  "person-detail.hobbies-error-max-length": "Must have a maximum of 1000 characters",
  "person-detail.preferred-language": "Preferred Language",
  "person-detail.phone-type-mobile": "Mobile",
  "person-detail.phone-type-landline": "Landline",
  "person-detail.phone-type": "Phone Type",
  "person-detail.phone-type-invalid": "Invalid phone type",
  "person-detail.preferred-method-communication": "Preferred Method of Communication",
  "person-detail.opted-out": "Opted Out",
  "person-detail.survey-opted-out": "Survey Opted Out",

  /* 18. Broadcast */
  "broadcast.title": "Broadcasts",
  "broadcast.name": "Subject",
  "broadcast.sent_to_count": "# Sent to",
  "broadcast.organization": "Organization",
  "broadcast.programs_recipients": "Programs/Recipients",
  "broadcast.upload-csv": "Upload Recipients from CSV file",
  "broadcast.message_type": "Message Type",
  "broadcast.replies_count": "Replies",
  "broadcast.status": "Status",
  "broadcast.date": "Date",
  "broadcast.date_timezone": "Timezone",
  "broadcast.filter.types.label": "Filter by Types",
  "broadcast.filter.status.label": "Filter by Status",
  "broadcast.list-create-button": "New Broadcast",
  "broadcast.list-create-button-title": "Create new broadcast",
  "broadcast.list-create-button-title-disabled": "Please select an organization",
  "broadcast.error-list": "No broadcasts found",
  "broadcast.options.edit": "Edit",
  "broadcast.options.details": "Details",
  "broadcast.options.delete": "Delete",
  "broadcast.options.archive": "Archive",
  "broadcast.options.duplicate": "Duplicate",
  "broadcast.confirmation.confirm.btn": "Confirm",
  "broadcast.confirmation.cancel.btn": "Cancel",
  "broadcast.confirmation.archive.title": "Archive Broadcast",
  "broadcast.confirmation.archive.message": "Are you sure you want to archive this broadcast and all associated information?",
  "broadcast.confirmation.delete.title": "Delete Broadcast",
  "broadcast.confirmation.delete.message": "Are you sure you want to delete this broadcast and all associated information?",

  /* 18. New Broadcast */
  "broadcast.new.title": "New Broadcast",
  "broadcast.new.section_1.title": "Creating a new Broadcast",
  "broadcast.new.section_1.paragraph_1":
    "Broadcast is a great way to send out a mass message to multiple recipients for a multitude of reasons, like call reminders, alerts, and important announcements.",
  "broadcast.new.section_1.paragraph_2": "Currently, you can record a voice memo to be played for callers/recipients, write a text message to push to users personal phones or send a group email.",
  "broadcast.new.section_2.title": "Instructions",
  "broadcast.new.section_2.paragraph_1": "1. Choose what kind of message you would like to broadcast (Voice / Email / SMS).",
  "broadcast.new.section_2.paragraph_2": "2. Select your recipients by choosing the organization in the dropdown and then selecting recipients that are a part of that organization.",
  "broadcast.new.section_2.paragraph_3": "3. Create your Broadcast by recording a message, writing a text message (SMS) or composing an email.",
  "broadcast.new.section_2.paragraph_4": '4. Press "Send Broadcast" for immediate delivery or "Save Draft", which will provide the ability to update your message later.',
  "broadcast.new.section_2.note": "Note: You can always save your information edit and/or publish later.",
  "broadcast.new.section_3.title": "Select Recipients",
  "broadcast.new.section_4.title": "Select Message Type",
  "broadcast.new.section_5.title": "Create Message",
  "broadcast.new.section_5.subtitle": "Subject",
  "broadcast.new.section_5.select_placeholder": "Enter text...",
  "broadcast.new.section_5.textarea_placeholder": "Enter message here...",
  "broadcast.new.section_5.message_prefix": "Message Preffix",
  "broadcast.new.section_5.message": "Message Content",
  "broadcast.new.section_6.title": "Schedule Message",
  "broadcast.new.section_6.subtitle_1": "Date",
  "broadcast.new.section_6.scheduled_placeholder": "Select or type...",
  "broadcast.new.section_6.subtitle_2": "Time",
  "broadcast.new.section_6.subtitle_3": "Time Zone",
  "broadcast.new.section_6.send_it_now": "Send Now",
  "broadcast.new.section_7.title": "Manage Replies",
  "broadcast.new.section_7.subtitle_1": "Allow Responses? *",
  "broadcast.new.section_7.subtitle_2": "Send Alerts To *",
  "broadcast.new.section_7.subtitle_3": "Email *",
  "broadcast.new.section_7.subtitle_3.placeholder": "Enter your email...",
  "broadcast.new.section_7.placeholder": "Select...",
  "broadcast.new.create.programs_recipients.add": "Add",
  "broadcast.new.create.programs_recipients.clear": "Clear",
  "broadcast.new.buttons.send": "Send Broadcast",
  "broadcast.new.buttons.save": "Save Draft",
  "broadcast.new.buttons.save_exit": "Save Draft & Exit",
  "broadcast.new.buttons.save_exit.ephemeral-disabled.tooltip": "You can't save a broadcast with uploaded recipients",
  "broadcast.new.create.success": "Broadcast saved successfully",
  "broadcast.new.delete.success": "Broadcast removed successfully",
  "broadcast.new.archive.success": "Broadcast archived successfully",
  "broadcast.new.sent.success": "Broadcast sent!",
  "broadcast.new.create.organization.id.required.validation": "Organization is required",
  "broadcast.new.create.type.required.validation": "Select one type of broadcast",
  "broadcast.new.create.content.sms.length.validation": "Content can't have more than 320 characters",
  "broadcast.new.create.content.length.validation": "Content can't have more than 4000 characters",
  "broadcast.new.create.content.required.validation": "You need create a message before sending broadcast",
  "broadcast.new.create.title.length.validation": "Title can't have more than 50 characters",
  "broadcast.new.create.title.required.validation": "Title is required",
  "broadcast.new.create.programs_recipients.length.validation": "Select and add at least one program or recipient",
  "broadcast.new.create.ephemeral_recipients.length.validation": "Upload a CSV file or select at least one recipient",
  "broadcast.new.create.programs_recipients.invalid": "No valid contact recipients were found for this organization.",

  /* 19. Broadcast Detail */
  "broadcast.detail.organization.title": "Organization Name",
  "broadcast.detail.message_type.title": "Message Type",
  "broadcast.detail.message.title": "Message",
  "broadcast.detail.program.title": "Program",
  "broadcast.detail.date.title": "Date Sent",
  "broadcast.detail.time.title": "Time Sent",
  "broadcast.detail.recipient.title": "Total Recipients Sent",
  "broadcast.detail.tab.recipient.title": "Recipients",
  "broadcast.detail.tab.response.title": "Responses",
  "broadcast.detail.table.recipient.col_1": "Name",
  "broadcast.detail.table.recipient.col_2": "Message Status",
  "broadcast.detail.table.recipient.contact": "Contact",
  "broadcast.detail.table.recipient.col_2_value": "NO",
  "broadcast.detail.table.response.col_1": "Name",
  "broadcast.detail.table.response.col_2": "Reply",
  "broadcast.detail.table.response.col_3": "Reply Date",
  "broadcast.detail.table.error-list": "No broadcasts recipients found",

  /* 20. Surveys */
  "survey.survey": "Survey",
  "survey.title": "Surveys",
  "survey.survey-results.title": "Survey Results",
  "survey.author": "Author",
  "survey.createdAt": "Creation Date",
  "survey.btn.submit": "Submit",
  "survey.btn.propagate": "Propagate All",
  "survey.btn.evaluation": "Evaluation Only",
  "survey.caller-survey.date-time": "DATE & TIME",
  "survey.caller-survey.change-of-condition": "CHANGE OF CONDITION",
  "survey.caller-survey.change-of-condition.input-placeholder": "Select...",
  "survey.caller-survey.additional-comments": "ADDITIONAL COMMENTS",
  "survey.caller-survey.additional-comments.input-placeholder": "Filter comments",
  "survey.caller-survey.admin-notes": "ADMIN NOTES",
  "survey.caller-survey.admin-notes.input-placeholder": "Filter notes",
  "survey.caller-survey.completed": "COMPLETED",
  "survey.caller-survey.not-found": "No Surveys found",
  "survey.caller-survey.invalid-date-time": "Expected format: mm/dd/yyyy",
  "survey.caller-survey.clear-filters": "Clear Filter",
  "survey.caller-survey.filter-by-organizations": "Filter by Organization",
  "survey.caller-survey.filter-by-program": "Filter by Program",
  "survey.survey-type.survey": "SURVEY",
  "survey.survey-type.survey.input-placeholder": "Select...",
  "survey.survey-type.conduct-survey": "Conduct {name}",
  "survey.survey-type.review-survey": "Review {name}",
  "survey.survey-type.disabled-conduct-survey-tooltip": "Survey can only be answered by people in the same organization",
  "survey.survey-type.disabled-conduct-follow-up-survey-tooltip": "Survey will be available after: {availableDate}",
  "survey.survey-type.view-survey": "View {name} Results",
  "survey.survey-type.respondent": "RESPONDENT",
  "survey.survey-type.recipient": "SUBJECT",

  /* 21.OrganizationPersonStatus */
  "organization-person-status.error-name-max-length": "Max 50 characters",
  "organization-person-status.error-name-min-length": "Min 3 characters, empty spaces are not allowed",
  "organization-person-status.error-description-max-length": "Max 200 characters",
  "organization-person-status.error-name-required": "Name is required",

  /* 22.Notes List */
  "notes.list.header.filter-by-organizations": "Filter by Organization",
  "notes.list.table-header.timestamp": "TIMESTAMP",
  "notes.list.table-header.from": "FROM",
  "notes.list.table-header.to": "TO",
  "notes.list.table-header.program": "PROGRAM",
  "notes.list.table-header.notes": "NOTES",
  "notes.list.empty-table": "No notes found",
  "notes.list.table-header.filters.program.placeholder": "Enter program",
  "notes.list.clear-filters": "Clear Filter",
  "notes.list.download": "Export",

  /* 23.Outreach Queue */
  "outreach-queue.table.header_01": "Call List",
  "outreach-queue.table.header_02": "Recipients",
  "outreach-queue.table.header_03": "Callers",
  "outreach-queue.table.header_04": "Last Call",
  "outreach-queue.table.header_05": "Active",
  "outreach-queue.table.empty": "Get started by creating your first Outreach Queue",
  "outreach-queue.table.last-call-conducted.never": "No calls were made using this Queue",
  "outreach-queue.btn.create": "Create Queue",
  "outreach-queue.btn.create.tooltip": "Click to create new Outreach Queue",
  "outreach-queue.btn.tooltip.export": "Export Queue",
  "outreach-queue.btn.tooltip.edit": "Edit Queue",
  "outreach-queue.btn.tooltip.delete": "Delete Queue",
  "outreach-queue.btn.tooltip.delete-self-signup": "Self Signup is a special queue that cannot be deleted",
  "outreach-queue.btn.tooltip.list-end": "Oops, no more entries",
  "outreach-queue.modal.recipients": "Assigned To Queue",
  "outreach-queue.modal.callers": "Queue Callers",
  "outreach-queue.modal.create": "Create",
  "outreach-queue.modal.edit": "Edit",
  "outreach-queue.modal.add": "Add",
  "outreach-queue.modal.header.self-signup": "Self Signup is a special queue that cannot be renamed",
  "outreach-queue.modal.delete": "Delete",
  "outreach-queue.modal.form.name": "Type the outreach queue name",
  "outreach-queue.modal.delete.message": "Are you sure you want to delete the Outreach Queue?",
  "outreach-queue.modal.delete.button-accept": "Accept",
  "outreach-queue.modal.delete.button-cancel": "Cancel",
  "outreach-queue.modal.person-list.header_01": "Order",
  "outreach-queue.modal.person-list.header_02": "Name",
  "outreach-queue.modal.person-list.header_03": "Last Active Call",
  "outreach-queue.modal.person-list.header_04": "Next Availability",
  "outreach-queue.modal.person-list.edit-btn": "Edit Next Availability",
  "outreach-queue.modal.person-list.delete-btn": "Remove Person",
  "outreach-queue.modal.person-list.pause-btn": "Pause the users availability in the queue for 1 day",
  "outreach-queue.modal.person-list.move-top-btn": "Move to top of the queue",
  "outreach-queue.modal.person-list.play-btn": "Resume the users availability in the queue",
  "outreach-queue.modal.person-list-dropdown": "Select an individual or group",
  "outreach-queue.modal.person-list-button-save": "Update",
  "outreach-queue.modal.person-list-button-cancel": "Cancel",
  "outreach-queue.modal.person-list-tooltip-next-availability": "Next availability",
  "outreach-queue.selected-call-logs.title": "Call logs from {name} Queue",

  /* 24.Administrative */
  "administrative.admin-panel.title": "Administrative Panel",
  "administrative.organization-feature.title": "Organization Features",
  "administrative.organization-feature.create.title": "Associate an organization to a feature",
  "administrative.organization-feature.organization-select.label": "Select one organization",
  "administrative.organization-feature.feature-select.label": "Select one feature",
  "administrative.outreach-queue.title": "Outreach Queue",
  "administrative.organization-list.title": "Organizations",
  "administrative.organization-detail.title": "Organization Detail",

  /* 25.Self Signup */
  "self-signup.accept-terms-of-service": "Agree DHI Terms",
  "self-signup.third-step.title1": "What's your communication style?",
  "self-signup.third-step.sub-title1": "Which of the following describes your phone call preferences?",
  "self-signup.third-step.sub-title2": "What language are you comfortable using for a social call?",
  "self-signup.third-step.sub-title3": "How many people would you like to call in a regular basis?",
  "self-signup.third-step.sub-title4": "Unexpected life events notwithstanding, can you commit to participate as a caller for at least three months?",
  "self-signup.fourth-step.title1": "Refer a friend",
  "self-signup.fourth-step.sub-title1": "Do you know of someone else who would like to participate in this program? If yes, with their consent, please enter details and we will reach out to them.",
  "self-signup.fiftieth-step.title1": "Almost Done!",
  "self-signup.fiftieth-step.sub-title1": "To confirm your identity, please solve the captcha below.",
  "self-signup.sixtieth-step.title1": "SIGN UP COMPLETE! 🎊",
}
